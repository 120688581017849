import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import { GraphQLError } from 'graphql-request/dist/types';
import { print } from 'graphql';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type AddAdpConnectionSslCertificateInput = {
  connectionId: Scalars['String'];
  privateKey: Scalars['String'];
  value: Scalars['String'];
};

export type AddAdpConnectionSslCertificateResult =
  | AdpConnectionSslCertificateAdded
  | ConnectionNotFound
  | InvalidAdpConnectionSslCertificate;

export type AddPaymentMethodPayload = {
  __typename?: 'AddPaymentMethodPayload';
  team: Team;
};

export type AddUsersToTeamInput = {
  users: Array<UserInviteInput>;
};

export type AddUsersToTeamPayload = {
  __typename?: 'AddUsersToTeamPayload';
  /** The list of memberships of the users that were just invited to the team. */
  invitedMembers?: Maybe<Array<UsersTeam>>;
};

export type AdpConnectionSslCertificate = {
  __typename?: 'AdpConnectionSslCertificate';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export type AdpConnectionSslCertificateAdded = {
  __typename?: 'AdpConnectionSslCertificateAdded';
  adpConnectionSslCertificate: AdpConnectionSslCertificate;
};

export type AdpConnectionSslCertificateDeleted = {
  __typename?: 'AdpConnectionSslCertificateDeleted';
  certificateId: Scalars['String'];
};

export type AdpConnectionSslCertificateNotFound = {
  __typename?: 'AdpConnectionSslCertificateNotFound';
  certificateId: Scalars['String'];
};

export type AttributeMap = {
  __typename?: 'AttributeMap';
  attributes: Attributes;
  id: Scalars['ID'];
};

export type Attributes = {
  __typename?: 'Attributes';
  email: Scalars['String'];
  firstName: Scalars['String'];
  idpId: Scalars['String'];
  lastName: Scalars['String'];
};

export type BambooHrDirectory = {
  __typename?: 'BambooHrDirectory';
  apiKey?: Maybe<Scalars['String']>;
  customFields?: Maybe<Array<Scalars['String']>>;
  subdomain?: Maybe<Scalars['String']>;
};

export type BreatheHrDirectory = {
  __typename?: 'BreatheHrDirectory';
  apiKey?: Maybe<Scalars['String']>;
};

export type Connection = {
  __typename?: 'Connection';
  adpConnectionSslCertificates: Array<AdpConnectionSslCertificate>;
  /** @deprecated Please use `attributeMap` instead. */
  attribute_map?: Maybe<AttributeMap>;
  attributeMap?: Maybe<AttributeMap>;
  connectionDomains: Array<ConnectionDomain>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  oauthCredential?: Maybe<OAuthCredential>;
  oauthSessions: OAuthSessionsList;
  oidc_client_id?: Maybe<Scalars['String']>;
  oidc_client_secret?: Maybe<Scalars['String']>;
  oidc_discovery_endpoint?: Maybe<Scalars['String']>;
  oidc_redirect_uri?: Maybe<Scalars['String']>;
  oidcSessions: OidcSessionsList;
  organization?: Maybe<Organization>;
  organizationDomains: Array<OrganizationDomain>;
  saml: SamlConnection;
  saml_acs_url: Scalars['String'];
  saml_entity_id?: Maybe<Scalars['String']>;
  saml_idp_metadata_url?: Maybe<Scalars['String']>;
  saml_idp_url?: Maybe<Scalars['String']>;
  saml_sp_metadata_url: Scalars['String'];
  /** @deprecated Please use `samlX509Certificates` instead. */
  saml_x509_certs?: Maybe<Array<Scalars['String']>>;
  samlSessions: SamlSessionsList;
  samlX509Certificates: Array<SamlX509Certificate>;
  state: ConnectionState;
  /** @deprecated Please use `state` instead. */
  status: ConnectionStatus;
  type: ConnectionType;
  updatedAt: Scalars['DateTime'];
};

export type ConnectionOauthSessionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<OAuthSessionState>;
};

export type ConnectionOidcSessionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<OidcSessionState>;
};

export type ConnectionSamlSessionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<SamlSessionState>;
};

export type ConnectionCreated = {
  __typename?: 'ConnectionCreated';
  connection: Connection;
};

export type ConnectionDomain = {
  __typename?: 'ConnectionDomain';
  domain: Scalars['String'];
  id: Scalars['ID'];
};

export type ConnectionError = {
  __typename?: 'ConnectionError';
  error: Scalars['String'];
  field: Scalars['String'];
  solution: Scalars['String'];
};

export type ConnectionList = {
  __typename?: 'ConnectionList';
  data: Array<Connection>;
  listMetadata: ListMetadata;
};

export type ConnectionNotFound = {
  __typename?: 'ConnectionNotFound';
  connectionId: Scalars['String'];
};

export type ConnectionSession = OAuthSession | OidcSession | SamlSession;

export type ConnectionSessionError =
  | DecryptionFailedError
  | InvalidAttributesError
  | InvalidX509CertError
  | MalformedSamlResponseError
  | ProfileNotAllowedOutsideOrganizationError;

/** Enum represents the error code of a Connection Session Error. */
export enum ConnectionSessionErrorCode {
  DecryptionFailed = 'DecryptionFailed',
  InvalidAttributes = 'InvalidAttributes',
  InvalidX509Cert = 'InvalidX509Cert',
  MalformedSamlResponse = 'MalformedSamlResponse',
  ProfileNotAllowedOutsideOrganization = 'ProfileNotAllowedOutsideOrganization',
}

export type ConnectionsMigratedToEnvironmentLevel = {
  __typename?: 'ConnectionsMigratedToEnvironmentLevel';
  environment: Environment;
};

/** Enum represents the state of the connection. */
export enum ConnectionState {
  Active = 'Active',
  Draft = 'Draft',
  Inactive = 'Inactive',
}

/** Enum represents the status of the connection. */
export enum ConnectionStatus {
  Linked = 'Linked',
  Unlinked = 'Unlinked',
}

/** Enum represents the type of connection. */
export enum ConnectionType {
  Adfssaml = 'ADFSSAML',
  AdpOidc = 'AdpOidc',
  Auth0Saml = 'Auth0SAML',
  AzureSaml = 'AzureSAML',
  CasSaml = 'CasSAML',
  ClassLinkSaml = 'ClassLinkSAML',
  CloudflareSaml = 'CloudflareSAML',
  CyberArkSaml = 'CyberArkSAML',
  DuoSaml = 'DuoSAML',
  GenericOidc = 'GenericOIDC',
  GenericSaml = 'GenericSAML',
  GoogleOAuth = 'GoogleOAuth',
  GoogleSaml = 'GoogleSAML',
  JumpCloudSaml = 'JumpCloudSAML',
  KeycloakSaml = 'KeycloakSAML',
  MagicLink = 'MagicLink',
  MicrosoftOAuth = 'MicrosoftOAuth',
  MiniOrangeSaml = 'MiniOrangeSAML',
  OktaSaml = 'OktaSAML',
  OneLoginSaml = 'OneLoginSAML',
  PingFederateSaml = 'PingFederateSAML',
  PingOneSaml = 'PingOneSAML',
  RipplingSaml = 'RipplingSAML',
  SalesforceSaml = 'SalesforceSAML',
  ShibbolethSaml = 'ShibbolethSAML',
  VMwareSaml = 'VMwareSAML',
}

export type ConnectionUpdated = {
  __typename?: 'ConnectionUpdated';
  connection: Connection;
};

export type ConnectionUpdatedFromMetadataUrl = {
  __typename?: 'ConnectionUpdatedFromMetadataUrl';
  connection: Connection;
};

export type ConnectionUpdatedFromMetadataXml = {
  __typename?: 'ConnectionUpdatedFromMetadataXml';
  connection: Connection;
};

export type CreateBillingPortalSessionPayload = {
  __typename?: 'CreateBillingPortalSessionPayload';
  billingPortalSessionUrl: Scalars['String'];
};

export type CreateConnectionInput = {
  name: Scalars['String'];
  organizationId: Scalars['String'];
  type: ConnectionType;
};

export type CreateConnectionResult =
  | ConnectionCreated
  | OauthConnectionMissingCredentials
  | OrganizationNotFound;

export type CreateDirectoryCustomAttributeInput = {
  environmentId: Scalars['String'];
  isRequired: Scalars['Boolean'];
  name: Scalars['String'];
};

export type CreateDirectoryCustomAttributeResult =
  DirectoryCustomAttributeCreated;

export type CreateKeyInput = {
  environmentId: Scalars['String'];
  expireAt?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
};

export type CreateKeyResult = KeyCreated;

export type CreateOauthCredentialsInput = {
  environmentId: Scalars['String'];
  provider: ConnectionType;
};

export type CreateOauthCredentialsPayload = {
  __typename?: 'CreateOauthCredentialsPayload';
  oauthCredentials?: Maybe<OAuthCredential>;
};

export type CreateOrganizationInput = {
  allowProfilesOutsideOrganization?: InputMaybe<Scalars['Boolean']>;
  domains?: InputMaybe<Array<Scalars['String']>>;
  environmentId: Scalars['String'];
  name: Scalars['String'];
};

export type CreateOrganizationResult =
  | EnvironmentNotFound
  | OrganizationCreated
  | OrganizationDomainAlreadyInUse
  | OrganizationMissingDomains;

export type DecryptionFailedError = {
  __typename?: 'DecryptionFailedError';
  code: ConnectionSessionErrorCode;
  reason: Scalars['String'];
};

export type DeleteAdpConnectionSslCertificateInput = {
  certificateId: Scalars['String'];
};

export type DeleteAdpConnectionSslCertificateResult =
  | AdpConnectionSslCertificateDeleted
  | AdpConnectionSslCertificateNotFound;

export type DeleteDirectoryCustomAttributeInput = {
  directoryCustomAttributeId: Scalars['ID'];
};

export type DeleteDirectoryCustomAttributeResult =
  | DirectoryCustomAttributeDeleted
  | DirectoryCustomAttributeNotFound;

export type DeleteDirectoryInput = {
  directoryId: Scalars['String'];
};

export type DeleteDirectoryPayload = {
  __typename?: 'DeleteDirectoryPayload';
  directory?: Maybe<Directory>;
};

export type DeleteOrganizationInput = {
  organizationId: Scalars['String'];
};

export type DeleteOrganizationPayload = {
  __typename?: 'DeleteOrganizationPayload';
  organization?: Maybe<Organization>;
};

export type DeleteSamlX509CertificateInput = {
  samlX509CertificateId: Scalars['String'];
};

export type DeleteSamlX509CertificateResult =
  | SamlX509CertificateDeleted
  | SamlX509CertificateNotFound;

export type Directory = {
  __typename?: 'Directory';
  attributeMap?: Maybe<DirectoryAttributeMap>;
  bamboo_hr_api_key?: Maybe<Scalars['String']>;
  bamboo_hr_custom_fields?: Maybe<Array<Scalars['String']>>;
  bamboo_hr_subdomain?: Maybe<Scalars['String']>;
  /** @deprecated Please use `bearerToken` instead. */
  bearer_token?: Maybe<Scalars['String']>;
  bearerToken?: Maybe<Scalars['String']>;
  breatheHrApiKey?: Maybe<Scalars['String']>;
  client_channel_token?: Maybe<Scalars['String']>;
  client_refresh_token?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** @deprecated Please use `attributeMap` instead. */
  directoryAttributeMap?: Maybe<DirectoryAttributeMap>;
  directoryGroups: DirectoryGroupsList;
  directorySyncRun: DirectorySyncRun;
  directorySyncRuns: DirectorySyncRunsList;
  directoryUser: DirectoryUser;
  directoryUsers: DirectoryUsersList;
  endpoint?: Maybe<Scalars['String']>;
  fourthHrOrganizationId?: Maybe<Scalars['String']>;
  fourthHrPassword?: Maybe<Scalars['String']>;
  fourthHrUsername?: Maybe<Scalars['String']>;
  gusto_company_id?: Maybe<Scalars['String']>;
  gusto_refresh_token?: Maybe<Scalars['String']>;
  hibobApiToken?: Maybe<Scalars['String']>;
  hibobServiceUserId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organization?: Maybe<Organization>;
  peopleHrApiKey?: Maybe<Scalars['String']>;
  primaryDomain?: Maybe<OrganizationDomain>;
  provider?: Maybe<DirectoryProvider>;
  rippling_api_key?: Maybe<Scalars['String']>;
  setup_url?: Maybe<Scalars['String']>;
  state: DirectoryState;
  summary: DirectorySummary;
  type: DirectoryType;
  updatedAt: Scalars['DateTime'];
  workday_raas_endpoint?: Maybe<Scalars['String']>;
  workday_raas_group_endpoint?: Maybe<Scalars['String']>;
  workday_raas_password?: Maybe<Scalars['String']>;
  workday_raas_username?: Maybe<Scalars['String']>;
};

export type DirectoryDirectoryGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type DirectoryDirectorySyncRunArgs = {
  id: Scalars['String'];
};

export type DirectoryDirectorySyncRunsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<DirectorySyncRunState>;
};

export type DirectoryDirectoryUserArgs = {
  id: Scalars['String'];
};

export type DirectoryDirectoryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};

export type DirectoryAttributeMap = {
  __typename?: 'DirectoryAttributeMap';
  attributes: DirectoryAttributes;
  /** @deprecated Please use `attributes.emails` instead. */
  emails_attribute?: Maybe<Scalars['String']>;
  /** @deprecated Please use `attributes.externalId` instead. */
  external_id_attribute?: Maybe<Scalars['String']>;
  /** @deprecated Please use `attributes.firstName` instead. */
  first_name_attribute?: Maybe<Scalars['String']>;
  /** @deprecated Please use `attributes.groupName` instead. */
  group_name_attribute?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** @deprecated Please use `attributes.lastName` instead. */
  last_name_attribute?: Maybe<Scalars['String']>;
  /** @deprecated Please use `attributes.username` instead. */
  username_attribute?: Maybe<Scalars['String']>;
};

export type DirectoryAttributes = {
  __typename?: 'DirectoryAttributes';
  emails?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  groupName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type DirectoryCustomAttribute = {
  __typename?: 'DirectoryCustomAttribute';
  createdAt: Scalars['DateTime'];
  environment: Environment;
  id: Scalars['ID'];
  isRequired: Scalars['Boolean'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type DirectoryCustomAttributeCreated = {
  __typename?: 'DirectoryCustomAttributeCreated';
  directoryCustomAttribute: DirectoryCustomAttribute;
};

export type DirectoryCustomAttributeDeleted = {
  __typename?: 'DirectoryCustomAttributeDeleted';
  directoryCustomAttribute: DirectoryCustomAttribute;
};

export type DirectoryCustomAttributeList = {
  __typename?: 'DirectoryCustomAttributeList';
  data: Array<DirectoryCustomAttribute>;
};

export type DirectoryCustomAttributeMapping = {
  __typename?: 'DirectoryCustomAttributeMapping';
  attribute: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customAttribute: DirectoryCustomAttribute;
  directory: Directory;
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type DirectoryCustomAttributeMappingInput = {
  attribute: Scalars['String'];
  customAttributeId: Scalars['ID'];
};

export type DirectoryCustomAttributeMappingList = {
  __typename?: 'DirectoryCustomAttributeMappingList';
  data: Array<DirectoryCustomAttributeMapping>;
};

export type DirectoryCustomAttributeMappingsSet = {
  __typename?: 'DirectoryCustomAttributeMappingsSet';
  mappings: Array<DirectoryCustomAttributeMapping>;
};

export type DirectoryCustomAttributeNotFound = {
  __typename?: 'DirectoryCustomAttributeNotFound';
  directoryCustomAttributeId: Scalars['String'];
};

export type DirectoryCustomAttributeUpdated = {
  __typename?: 'DirectoryCustomAttributeUpdated';
  directoryCustomAttribute: DirectoryCustomAttribute;
};

export type DirectoryGroup = {
  __typename?: 'DirectoryGroup';
  id: Scalars['String'];
  name: Scalars['String'];
  summary: DirectoryGroupSummary;
};

export type DirectoryGroupsList = {
  __typename?: 'DirectoryGroupsList';
  data: Array<DirectoryGroup>;
  listMetadata: ListMetadata;
};

export type DirectoryGroupSummary = {
  __typename?: 'DirectoryGroupSummary';
  memberCount: Scalars['Int'];
};

export type DirectoryList = {
  __typename?: 'DirectoryList';
  data: Array<Directory>;
  listMetadata: ListMetadata;
};

export type DirectoryNotFound = {
  __typename?: 'DirectoryNotFound';
  directoryId: Scalars['String'];
};

export type DirectoryProvider =
  | BambooHrDirectory
  | BreatheHrDirectory
  | FourthHrDirectory
  | GustoDirectory
  | HibobDirectory
  | PeopleHrDirectory
  | RipplingDirectory
  | WorkdayDirectory;

/** An error pertaining to the directory provider. */
export type DirectoryProviderError = {
  __typename?: 'DirectoryProviderError';
  message: Scalars['String'];
};

export type DirectoryProviderErrors = {
  __typename?: 'DirectoryProviderErrors';
  errors: Array<DirectoryProviderError>;
};

/** Enum represents the state of the Directory. */
export enum DirectoryState {
  Deleting = 'Deleting',
  InvalidCredentials = 'InvalidCredentials',
  Linked = 'Linked',
  Unlinked = 'Unlinked',
  Validating = 'Validating',
}

export type DirectorySummary = {
  __typename?: 'DirectorySummary';
  groupCount: Scalars['Int'];
  userCount: Scalars['Int'];
};

export type DirectorySyncRun = {
  __typename?: 'DirectorySyncRun';
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  directoryId: Scalars['String'];
  errors: Array<DirectorySyncRunError>;
  groupCounts: DirectorySyncRunResourceCounts;
  id: Scalars['String'];
  state: DirectorySyncRunState;
  updatedAt: Scalars['DateTime'];
  userCounts: DirectorySyncRunResourceCounts;
};

export type DirectorySyncRunError =
  | MalformedDirectoryGroupError
  | MalformedDirectoryUserError;

/** Enum represents the error code of a Directory Sync Run. */
export enum DirectorySyncRunErrorCode {
  MalformedDirectoryGroup = 'MalformedDirectoryGroup',
  MalformedDirectoryUser = 'MalformedDirectoryUser',
}

export type DirectorySyncRunResourceCounts = {
  __typename?: 'DirectorySyncRunResourceCounts';
  created: Scalars['Float'];
  deleted: Scalars['Float'];
  processed: Scalars['Float'];
  updated: Scalars['Float'];
};

export type DirectorySyncRunsList = {
  __typename?: 'DirectorySyncRunsList';
  data: Array<DirectorySyncRun>;
  listMetadata: ListMetadata;
};

/** Enum represents the state of the directory sync run. */
export enum DirectorySyncRunState {
  Completed = 'Completed',
  Error = 'Error',
  New = 'New',
  Running = 'Running',
}

/** Enum represents the type of Directory. */
export enum DirectoryType {
  AzureScimv2_0 = 'AzureSCIMV2_0',
  BambooHr = 'BambooHR',
  BreatheHr = 'BreatheHr',
  CyberArkScimV2_0 = 'CyberArkScimV2_0',
  FourthHr = 'FourthHr',
  GenericScimv1_1 = 'GenericSCIMV1_1',
  GenericScimv2_0 = 'GenericSCIMV2_0',
  GSuiteDirectory = 'GSuiteDirectory',
  Gusto = 'Gusto',
  Hibob = 'Hibob',
  JumpCloudScimV2_0 = 'JumpCloudScimV2_0',
  OktaScimv1_1 = 'OktaSCIMV1_1',
  OktaScimv2_0 = 'OktaSCIMV2_0',
  OneLoginScimV2_0 = 'OneLoginScimV2_0',
  PeopleHr = 'PeopleHr',
  PingFederateScimV2_0 = 'PingFederateScimV2_0',
  Rippling = 'Rippling',
  S3 = 'S3',
  Workday = 'Workday',
}

export type DirectoryUpdated = {
  __typename?: 'DirectoryUpdated';
  directory: Directory;
};

export type DirectoryUser = {
  __typename?: 'DirectoryUser';
  directoryGroups: Array<DirectoryGroup>;
  emails?: Maybe<DirectoryUserEmail>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  idpId: Scalars['String'];
  /** The JSON representation of the Directory User, as returned by the REST API. */
  json: Scalars['JSON'];
  lastName?: Maybe<Scalars['String']>;
  state: DirectoryUserState;
  username?: Maybe<Scalars['String']>;
};

export type DirectoryUserEmail = {
  __typename?: 'DirectoryUserEmail';
  primary: Scalars['Boolean'];
  type: Scalars['String'];
  value: Scalars['String'];
};

export type DirectoryUsersList = {
  __typename?: 'DirectoryUsersList';
  data: Array<DirectoryUser>;
  listMetadata: ListMetadata;
};

/** Enum represents the state of the directory user. */
export enum DirectoryUserState {
  Active = 'Active',
  Inactive = 'Inactive',
  Suspended = 'Suspended',
}

/** Represents the email categories that can be unsubscribed from. */
export enum EmailNotificationCategory {
  Connection = 'Connection',
  Error = 'Error',
}

export type EmailNotificationPreference = {
  __typename?: 'EmailNotificationPreference';
  category: EmailNotificationCategory;
  preference: EmailNotificationPreferenceConfiguration;
};

/** Represents the possible configurations for email notification preferences. */
export enum EmailNotificationPreferenceConfiguration {
  Subscribed = 'Subscribed',
  Unsubscribed = 'Unsubscribed',
}

export type EmailNotificationPreferences = {
  __typename?: 'EmailNotificationPreferences';
  preferences: Array<EmailNotificationPreference>;
};

export type EnterTeamDetailsPayload = {
  __typename?: 'EnterTeamDetailsPayload';
  team?: Maybe<Team>;
  user?: Maybe<User>;
};

export type Environment = {
  __typename?: 'Environment';
  allowProfilesOutsideOrganization?: Maybe<Scalars['Boolean']>;
  /** @deprecated Please use `clientId` instead. */
  client_id: Scalars['String'];
  clientId: Scalars['String'];
  id: Scalars['ID'];
  /** @deprecated Please use `logoUrl` instead. */
  logo_url?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  oauthAndMagicLinkMigrationStatuses: OauthAndMagicLinkMigrationStatuses;
  portalSettings: PortalSettings;
  projectV2: ProjectV2;
  sandbox: Scalars['Boolean'];
  /** @deprecated Please use `ssoDemoApplication` instead. */
  sso_demo_application?: Maybe<SsoDemoApplication>;
  ssoDemoApplication?: Maybe<SsoDemoApplication>;
};

export type EnvironmentNotFound = {
  __typename?: 'EnvironmentNotFound';
  environmentId: Scalars['String'];
};

export type EnvironmentUpdated = {
  __typename?: 'EnvironmentUpdated';
  environment: Environment;
};

export type Event = {
  __typename?: 'Event';
  action: EventAction;
  actor: EventActor;
  group: Scalars['String'];
  id: Scalars['ID'];
  latitude?: Maybe<Scalars['Float']>;
  location: Scalars['String'];
  longitude?: Maybe<Scalars['Float']>;
  metadata?: Maybe<Scalars['JSON']>;
  occurredAt: Scalars['DateTime'];
  target: EventTarget;
  type?: Maybe<Scalars['String']>;
};

export type EventAction = {
  __typename?: 'EventAction';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type EventActor = {
  __typename?: 'EventActor';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type EventList = {
  __typename?: 'EventList';
  data: Array<Event>;
  listMetadata: ListMetadata;
};

export type EventTarget = {
  __typename?: 'EventTarget';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ExpectedAttributes = {
  __typename?: 'ExpectedAttributes';
  email_attribute: Scalars['String'];
  first_name_attribute: Scalars['String'];
  idp_id_attribute: Scalars['String'];
  last_name_attribute: Scalars['String'];
};

export type ExpireKeyInput = {
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  keyId: Scalars['String'];
};

export type ExpireKeyResult = KeyAlreadyExpired | KeyExpired | KeyNotFound;

export type FourthHrDirectory = {
  __typename?: 'FourthHrDirectory';
  organizationId?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type GeneratePortalLinkResult =
  | OrganizationNotFound
  | PortalLinkGenerated
  | SsoAlreadyConnectedForDomain
  | UserNotAuthorized;

export type GeneratePortalSetupLinkInput = {
  intent: PortalSetupLinkIntent;
  organizationId: Scalars['String'];
};

export type GeneratePortalSetupLinkResult =
  | OrganizationNotFound
  | PortalSetupLinkGenerated;

export type GustoDirectory = {
  __typename?: 'GustoDirectory';
  companyId?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type HibobDirectory = {
  __typename?: 'HibobDirectory';
  apiToken?: Maybe<Scalars['String']>;
  serviceUserId?: Maybe<Scalars['String']>;
};

export type InvalidAdpConnectionSslCertificate = {
  __typename?: 'InvalidAdpConnectionSslCertificate';
  privateKey: Scalars['String'];
  value: Scalars['String'];
};

export type InvalidAttributesError = {
  __typename?: 'InvalidAttributesError';
  code: ConnectionSessionErrorCode;
  expected_attributes: ExpectedAttributes;
  reason: Scalars['String'];
  received_attributes: Array<ReceivedAttribute>;
};

export type InvalidRedirectUriProcotol = {
  __typename?: 'InvalidRedirectUriProcotol';
  message: Scalars['String'];
  uri: Scalars['String'];
};

export type InvalidSamlX509Certificate = {
  __typename?: 'InvalidSamlX509Certificate';
  validationError: SamlX509CertificateValidationErrorCode;
};

export type InvalidX509CertError = {
  __typename?: 'InvalidX509CertError';
  code: ConnectionSessionErrorCode;
  reason: Scalars['String'];
};

export type Key = {
  __typename?: 'Key';
  createdAt: Scalars['DateTime'];
  displayValue: Scalars['String'];
  expiredAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: KeyType;
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export type KeyAlreadyExpired = {
  __typename?: 'KeyAlreadyExpired';
  key: Key;
};

export type KeyCreated = {
  __typename?: 'KeyCreated';
  key: Key;
};

export type KeyExpired = {
  __typename?: 'KeyExpired';
  expiredKey: Key;
  newKey?: Maybe<Key>;
};

export type KeyList = {
  __typename?: 'KeyList';
  data: Array<Key>;
  listMetadata: ListMetadata;
};

export type KeyNotFound = {
  __typename?: 'KeyNotFound';
  keyId: Scalars['String'];
};

/** Enum represents a scope of API keys that can be returned. */
export enum KeyScope {
  Active = 'Active',
  All = 'All',
  RecentlyExpired = 'RecentlyExpired',
}

/** Enum representing a type of key. */
export enum KeyType {
  Secret = 'SECRET',
}

export type ListMetadata = {
  __typename?: 'ListMetadata';
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
};

export type MalformedDirectoryGroupError = {
  __typename?: 'MalformedDirectoryGroupError';
  code: DirectorySyncRunErrorCode;
  reason: Scalars['String'];
};

export type MalformedDirectoryUserError = {
  __typename?: 'MalformedDirectoryUserError';
  code: DirectorySyncRunErrorCode;
  reason: Scalars['String'];
};

export type MalformedSamlResponseError = {
  __typename?: 'MalformedSamlResponseError';
  code: ConnectionSessionErrorCode;
  reason: Scalars['String'];
};

export type MetadataFetchFailed = {
  __typename?: 'MetadataFetchFailed';
  reason: Scalars['String'];
};

export type MetadataParseFailed = {
  __typename?: 'MetadataParseFailed';
  reason: Scalars['String'];
};

export type MigrateConnectionsToEnvironmentLevelInput = {
  environmentId: Scalars['String'];
};

export type MigrateConnectionsToEnvironmentLevelResult =
  | ConnectionsMigratedToEnvironmentLevel
  | EnvironmentNotFound;

export type Mutation = {
  __typename?: 'Mutation';
  addAdpConnectionSslCertificate: AddAdpConnectionSslCertificateResult;
  addPaymentMethod: AddPaymentMethodPayload;
  addUsersToTeam: AddUsersToTeamPayload;
  addUserToTeam: UsersTeam;
  changeRole: UsersTeam;
  createBillingPortalSession: CreateBillingPortalSessionPayload;
  createConnection: CreateConnectionResult;
  createDirectory: Directory;
  createDirectoryCustomAttribute: CreateDirectoryCustomAttributeResult;
  createEnvironment: Environment;
  createKey: CreateKeyResult;
  createOauthCredentials: CreateOauthCredentialsPayload;
  createOrganization: CreateOrganizationResult;
  createProject: ProjectV2;
  createRedirectUri: RedirectUri;
  createWebhookEndpoint: WebhookEndpoint;
  deleteAdpConnectionSslCertificate: DeleteAdpConnectionSslCertificateResult;
  deleteConnection: Scalars['String'];
  deleteDirectory: DeleteDirectoryPayload;
  deleteDirectoryCustomAttribute: DeleteDirectoryCustomAttributeResult;
  deleteOrganization: DeleteOrganizationPayload;
  deleteRedirectUri: Scalars['String'];
  deleteSamlX509Certificate: DeleteSamlX509CertificateResult;
  deleteWebhookEndpoint: Scalars['String'];
  expireKey: ExpireKeyResult;
  generatePortalLink: GeneratePortalLinkResult;
  generatePortalSetupLink: GeneratePortalSetupLinkResult;
  migrateConnectionsToEnvironmentLevel: MigrateConnectionsToEnvironmentLevelResult;
  onboarding_completeStep: OnboardingCompleteStepPayload;
  onboarding_enterTeamDetails: EnterTeamDetailsPayload;
  portal_addAdpConnectionSslCertificate: Portal_AddAdpConnectionSslCertificateResult;
  portal_createConnection: Portal_Connection;
  portal_createDirectory: Portal_Directory;
  portal_deleteDirectory: Portal_DeleteDirectoryPayload;
  portal_deleteDraftConnection: Scalars['String'];
  portal_deleteSamlX509Certificate: Portal_DeleteSamlX509CertificateResult;
  portal_loaded: SuccessResponse;
  portal_ready: SuccessResponse;
  portal_setDirectoryCustomAttributeMappings: Portal_SetDirectoryCustomAttributeMappingsResult;
  portal_trackEvent: SuccessResponse;
  portal_updateAttributeMap?: Maybe<AttributeMap>;
  portal_updateConnection: Portal_UpdateConnectionResult;
  portal_updateConnectionFromMetadataUrl: Portal_UpdateConnectionFromMetadataUrlResult;
  portal_updateConnectionFromMetadataXml: Portal_UpdateConnectionFromMetadataXmlResult;
  portal_updateDirectory: Portal_Directory;
  portal_updateDirectoryAttributeMap?: Maybe<Portal_UpdateDirectoryAttributeMapPayload>;
  portalIdentityProviderRequest: Scalars['Boolean'];
  provisionTestAppAndUser: Team;
  removeUserFromTeam: Scalars['String'];
  setDirectoryCustomAttributeMappings: SetDirectoryCustomAttributeMappingsResult;
  setDomainsForOrganization: SetDomainsForOrganizationPayload;
  setEmailNotificationPreferences: SetEmailNotificationPreferencesResult;
  setRedirectUris: SetRedirectUrisResult;
  testWebhookEndpoint: Webhook;
  updateAttributeMap?: Maybe<AttributeMap>;
  updateConnection: UpdateConnectionResult;
  updateConnectionFromMetadataUrl: UpdateConnectionFromMetadataUrlResult;
  updateConnectionFromMetadataXml: UpdateConnectionFromMetadataXmlResult;
  updateDirectory: UpdateDirectoryResult;
  updateDirectoryAttributeMap?: Maybe<DirectoryAttributeMap>;
  updateDirectoryCustomAttribute: UpdateDirectoryCustomAttributeResult;
  updateEnvironment: UpdateEnvironmentResult;
  updateOAuthCredential?: Maybe<OAuthCredential>;
  updateOrganization: UpdateOrganizationPayload;
  updatePortalSettings: PortalSettings;
  updateProjectV2: ProjectV2;
  updateRedirectUri: RedirectUri;
  updateSession?: Maybe<Session>;
  updateTeam: Team;
  updateWebhookEndpoint: WebhookEndpoint;
  upsertSamlX509Certificate: UpsertSamlX509CertificateResult;
};

export type MutationAddAdpConnectionSslCertificateArgs = {
  input: AddAdpConnectionSslCertificateInput;
};

export type MutationAddPaymentMethodArgs = {
  billingEmail: Scalars['String'];
  stripePaymentMethodId: Scalars['String'];
};

export type MutationAddUsersToTeamArgs = {
  input: AddUsersToTeamInput;
};

export type MutationAddUserToTeamArgs = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  role: UsersOrganizationsRole;
};

export type MutationChangeRoleArgs = {
  role: UsersOrganizationsRole;
  usersOrganizationsId: Scalars['String'];
};

export type MutationCreateBillingPortalSessionArgs = {
  returnUrl: Scalars['String'];
};

export type MutationCreateConnectionArgs = {
  input: CreateConnectionInput;
};

export type MutationCreateDirectoryArgs = {
  domain?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organization_id: Scalars['String'];
  type: DirectoryType;
};

export type MutationCreateDirectoryCustomAttributeArgs = {
  input: CreateDirectoryCustomAttributeInput;
};

export type MutationCreateEnvironmentArgs = {
  name: Scalars['String'];
  project_id: Scalars['String'];
  sandbox: Scalars['Boolean'];
};

export type MutationCreateKeyArgs = {
  input: CreateKeyInput;
};

export type MutationCreateOauthCredentialsArgs = {
  input: CreateOauthCredentialsInput;
};

export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};

export type MutationCreateProjectArgs = {
  name: Scalars['String'];
};

export type MutationCreateRedirectUriArgs = {
  environmentId: Scalars['String'];
  isDefault: Scalars['Boolean'];
  uri: Scalars['String'];
};

export type MutationCreateWebhookEndpointArgs = {
  endpointUrl: Scalars['String'];
  environmentId: Scalars['String'];
  events: Array<Scalars['String']>;
};

export type MutationDeleteAdpConnectionSslCertificateArgs = {
  input: DeleteAdpConnectionSslCertificateInput;
};

export type MutationDeleteConnectionArgs = {
  id: Scalars['String'];
};

export type MutationDeleteDirectoryArgs = {
  input: DeleteDirectoryInput;
};

export type MutationDeleteDirectoryCustomAttributeArgs = {
  input: DeleteDirectoryCustomAttributeInput;
};

export type MutationDeleteOrganizationArgs = {
  input: DeleteOrganizationInput;
};

export type MutationDeleteRedirectUriArgs = {
  id: Scalars['String'];
};

export type MutationDeleteSamlX509CertificateArgs = {
  input: DeleteSamlX509CertificateInput;
};

export type MutationDeleteWebhookEndpointArgs = {
  id: Scalars['String'];
};

export type MutationExpireKeyArgs = {
  input: ExpireKeyInput;
};

export type MutationGeneratePortalSetupLinkArgs = {
  input: GeneratePortalSetupLinkInput;
};

export type MutationMigrateConnectionsToEnvironmentLevelArgs = {
  input: MigrateConnectionsToEnvironmentLevelInput;
};

export type MutationOnboarding_CompleteStepArgs = {
  input: OnboardingCompleteStepInput;
};

export type MutationOnboarding_EnterTeamDetailsArgs = {
  input: OnboardingEnterTeamDetailsInput;
};

export type MutationPortal_AddAdpConnectionSslCertificateArgs = {
  input: Portal_AddAdpConnectionSslCertificateInput;
};

export type MutationPortal_CreateConnectionArgs = {
  connection_type: ConnectionType;
  domains?: InputMaybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  organization_domain_ids?: InputMaybe<Array<Scalars['String']>>;
};

export type MutationPortal_CreateDirectoryArgs = {
  domain?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  type: DirectoryType;
};

export type MutationPortal_DeleteDirectoryArgs = {
  input: Portal_DeleteDirectoryInput;
};

export type MutationPortal_DeleteDraftConnectionArgs = {
  id: Scalars['String'];
};

export type MutationPortal_DeleteSamlX509CertificateArgs = {
  input: Portal_DeleteSamlX509CertificateInput;
};

export type MutationPortal_LoadedArgs = {
  legacy?: InputMaybe<Scalars['Boolean']>;
  started_at: Scalars['Float'];
};

export type MutationPortal_ReadyArgs = {
  legacy?: InputMaybe<Scalars['Boolean']>;
  started_at: Scalars['Float'];
};

export type MutationPortal_SetDirectoryCustomAttributeMappingsArgs = {
  input: Portal_SetDirectoryCustomAttributeMappingsInput;
};

export type MutationPortal_TrackEventArgs = {
  attributes?: InputMaybe<Scalars['JSON']>;
  event_name: Scalars['String'];
};

export type MutationPortal_UpdateAttributeMapArgs = {
  attribute_map_id: Scalars['String'];
  email_attribute?: InputMaybe<Scalars['String']>;
  first_name_attribute?: InputMaybe<Scalars['String']>;
  idp_id_attribute?: InputMaybe<Scalars['String']>;
  last_name_attribute?: InputMaybe<Scalars['String']>;
};

export type MutationPortal_UpdateConnectionArgs = {
  input: Portal_UpdateConnectionInput;
};

export type MutationPortal_UpdateConnectionFromMetadataUrlArgs = {
  input: Portal_UpdateConnectionFromMetadataUrlInput;
};

export type MutationPortal_UpdateConnectionFromMetadataXmlArgs = {
  input: Portal_UpdateConnectionFromMetadataXmlInput;
};

export type MutationPortal_UpdateDirectoryArgs = {
  bamboo_hr_api_key?: InputMaybe<Scalars['String']>;
  bamboo_hr_subdomain?: InputMaybe<Scalars['String']>;
  bob_api_token?: InputMaybe<Scalars['String']>;
  bob_service_user_id?: InputMaybe<Scalars['String']>;
  breatheHrApiKey?: InputMaybe<Scalars['String']>;
  directory_id: Scalars['String'];
  domain?: InputMaybe<Scalars['String']>;
  fourthHrOrganizationId?: InputMaybe<Scalars['String']>;
  fourthHrPassword?: InputMaybe<Scalars['String']>;
  fourthHrUsername?: InputMaybe<Scalars['String']>;
  peopleHrApiKey?: InputMaybe<Scalars['String']>;
  rippling_api_key?: InputMaybe<Scalars['String']>;
  workday_raas_endpoint?: InputMaybe<Scalars['String']>;
  workday_raas_group_endpoint?: InputMaybe<Scalars['String']>;
  workday_raas_password?: InputMaybe<Scalars['String']>;
  workday_raas_username?: InputMaybe<Scalars['String']>;
};

export type MutationPortal_UpdateDirectoryAttributeMapArgs = {
  input: Portal_UpdateDirectoryAttributeMapInput;
};

export type MutationPortalIdentityProviderRequestArgs = {
  identityProvider: Scalars['String'];
};

export type MutationProvisionTestAppAndUserArgs = {
  input: ProvisionTestAppAndUserInput;
};

export type MutationRemoveUserFromTeamArgs = {
  usersOrganizationsId: Scalars['String'];
};

export type MutationSetDirectoryCustomAttributeMappingsArgs = {
  input: SetDirectoryCustomAttributeMappingsInput;
};

export type MutationSetDomainsForOrganizationArgs = {
  input: SetDomainsForOrganizationInput;
};

export type MutationSetEmailNotificationPreferencesArgs = {
  input: SetEmailNotificationPreferencesInput;
};

export type MutationSetRedirectUrisArgs = {
  input: SetRedirectUrisInput;
};

export type MutationTestWebhookEndpointArgs = {
  event: WebhookEventFixtures;
  id: Scalars['String'];
};

export type MutationUpdateAttributeMapArgs = {
  attribute_map_id: Scalars['String'];
  email_attribute?: InputMaybe<Scalars['String']>;
  first_name_attribute?: InputMaybe<Scalars['String']>;
  idp_id_attribute?: InputMaybe<Scalars['String']>;
  last_name_attribute?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateConnectionArgs = {
  input: UpdateConnectionInput;
};

export type MutationUpdateConnectionFromMetadataUrlArgs = {
  input: UpdateConnectionFromMetadataUrlInput;
};

export type MutationUpdateConnectionFromMetadataXmlArgs = {
  input: UpdateConnectionFromMetadataXmlInput;
};

export type MutationUpdateDirectoryArgs = {
  input: UpdateDirectoryInput;
};

export type MutationUpdateDirectoryAttributeMapArgs = {
  directory_attribute_map_id: Scalars['String'];
  emails_attribute?: InputMaybe<Scalars['String']>;
  external_id_attribute?: InputMaybe<Scalars['String']>;
  first_name_attribute?: InputMaybe<Scalars['String']>;
  group_name_attribute?: InputMaybe<Scalars['String']>;
  last_name_attribute?: InputMaybe<Scalars['String']>;
  username_attribute?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateDirectoryCustomAttributeArgs = {
  input: UpdateDirectoryCustomAttributeInput;
};

export type MutationUpdateEnvironmentArgs = {
  input: UpdateEnvironmentInput;
};

export type MutationUpdateOAuthCredentialArgs = {
  client_id?: InputMaybe<Scalars['String']>;
  client_secret?: InputMaybe<Scalars['String']>;
  oauth_credential_id: Scalars['String'];
};

export type MutationUpdateOrganizationArgs = {
  allowProfilesOutsideOrganization?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type MutationUpdatePortalSettingsArgs = {
  default_redirect_link: Scalars['String'];
  portal_settings_id: Scalars['String'];
};

export type MutationUpdateProjectV2Args = {
  name: Scalars['String'];
  project_id: Scalars['String'];
};

export type MutationUpdateRedirectUriArgs = {
  isDefault?: InputMaybe<Scalars['Boolean']>;
  redirectUriId: Scalars['String'];
  uri?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateSessionArgs = {
  session: UpdateSessionInput;
};

export type MutationUpdateTeamArgs = {
  id: Scalars['String'];
  provider?: InputMaybe<TeamsProvider>;
  sdk_language?: InputMaybe<Scalars['String']>;
  sso_onboarding_step?: InputMaybe<Scalars['Int']>;
};

export type MutationUpdateWebhookEndpointArgs = {
  endpointUrl?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<Array<Scalars['String']>>;
  state?: InputMaybe<WebhookEndpointState>;
  webhookEndpointId: Scalars['String'];
};

export type MutationUpsertSamlX509CertificateArgs = {
  input: UpsertSamlX509CertificateInput;
};

export type OauthAndMagicLinkMigrationStatus = {
  __typename?: 'OauthAndMagicLinkMigrationStatus';
  connectionsToMigrate: Scalars['Int'];
  needsMigration: Scalars['Boolean'];
};

export type OauthAndMagicLinkMigrationStatuses = {
  __typename?: 'OauthAndMagicLinkMigrationStatuses';
  magicLink: OauthAndMagicLinkMigrationStatus;
  oauth: OauthAndMagicLinkMigrationStatus;
};

export type OauthConnectionMissingCredentials = {
  __typename?: 'OauthConnectionMissingCredentials';
  connectionId?: Maybe<Scalars['String']>;
};

export type OAuthCredential = {
  __typename?: 'OAuthCredential';
  /** @deprecated Please use `clientId` instead. */
  client_id?: Maybe<Scalars['String']>;
  /** @deprecated Please use `clientSecret` instead. */
  client_secret?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  /** @deprecated Please use `externalKey` instead. */
  external_key: Scalars['String'];
  externalKey: Scalars['String'];
  id: Scalars['ID'];
  /** @deprecated Please use `redirectUri` instead. */
  redirect_uri?: Maybe<Scalars['String']>;
  redirectUri?: Maybe<Scalars['String']>;
  state: OAuthCredentialState;
  type: ConnectionType;
};

export type OAuthCredentialList = {
  __typename?: 'OAuthCredentialList';
  data: Array<OAuthCredential>;
  listMetadata: ListMetadata;
};

/** Enum represents the state of the OAuth Credential. */
export enum OAuthCredentialState {
  Invalid = 'Invalid',
  Valid = 'Valid',
}

export type OAuthSession = {
  __typename?: 'OAuthSession';
  createdAt: Scalars['DateTime'];
  errors: Array<ConnectionSessionError>;
  id: Scalars['ID'];
  profile?: Maybe<Profile>;
  state: OAuthSessionState;
  /** @deprecated Please use `stateParameter` instead. */
  state_parameter: Scalars['String'];
  stateParameter: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type OAuthSessionsList = {
  __typename?: 'OAuthSessionsList';
  data: Array<OAuthSession>;
  listMetadata: ListMetadata;
};

/** Enum represents the state of the OAuth Session. */
export enum OAuthSessionState {
  Authorized = 'Authorized',
  Failed = 'Failed',
  Started = 'Started',
  Successful = 'Successful',
}

export type OidcSession = {
  __typename?: 'OidcSession';
  createdAt: Scalars['DateTime'];
  errors: Array<ConnectionSessionError>;
  id: Scalars['ID'];
  profile?: Maybe<Profile>;
  state: OidcSessionState;
  stateParameter?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type OidcSessionsList = {
  __typename?: 'OidcSessionsList';
  data: Array<OidcSession>;
  listMetadata: ListMetadata;
};

/** Enum represents the state of the OIDC Session. */
export enum OidcSessionState {
  Authorized = 'Authorized',
  Failed = 'Failed',
  Started = 'Started',
  Successful = 'Successful',
}

export type OnboardingCompanyDetailsInput = {
  name: Scalars['String'];
  size: Scalars['String'];
};

export type OnboardingCompleteStepInput = {
  /** The next step in the onboarding process, if one exists. */
  nextStep?: InputMaybe<Scalars['String']>;
};

export type OnboardingCompleteStepPayload = {
  __typename?: 'OnboardingCompleteStepPayload';
  team?: Maybe<Team>;
};

export type OnboardingEnterTeamDetailsInput = {
  company: OnboardingCompanyDetailsInput;
  /** Whether the CRM integration should be disabled. */
  noCrm?: InputMaybe<Scalars['Boolean']>;
  urgency: Scalars['String'];
  user: OnboardingUserDetailsInput;
};

export type OnboardingUserDetailsInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  role: Scalars['String'];
};

export type Organization = {
  __typename?: 'Organization';
  allowProfilesOutsideOrganization: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  domains: Array<OrganizationDomain>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type OrganizationCreated = {
  __typename?: 'OrganizationCreated';
  organization: Organization;
};

export type OrganizationDomain = {
  __typename?: 'OrganizationDomain';
  domain: Scalars['String'];
  id: Scalars['ID'];
};

export type OrganizationDomainAlreadyInUse = {
  __typename?: 'OrganizationDomainAlreadyInUse';
  domain: Scalars['String'];
  organization: Organization;
};

export type OrganizationList = {
  __typename?: 'OrganizationList';
  data: Array<Organization>;
  listMetadata: ListMetadata;
};

/** An error indicating that the Organization is missing required domains. For example, an Organization that does not allow Profiles outside of it requires at least one User Email Domain. */
export type OrganizationMissingDomains = {
  __typename?: 'OrganizationMissingDomains';
  /** The ID of the impacted Organization. This will be `null` if this error is encountered while creating a new Organization. */
  organizationId?: Maybe<Scalars['String']>;
};

export type OrganizationNotFound = {
  __typename?: 'OrganizationNotFound';
  organizationId: Scalars['String'];
};

export type PasswordlessSession = {
  __typename?: 'PasswordlessSession';
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  expiresAt: Scalars['DateTime'];
  id: Scalars['ID'];
  profile?: Maybe<Profile>;
  state: PasswordlessSessionState;
  updatedAt: Scalars['DateTime'];
};

export type PasswordlessSessionList = {
  __typename?: 'PasswordlessSessionList';
  data: Array<PasswordlessSession>;
  listMetadata: ListMetadata;
};

/** Enum represents the state of the Passwordless Session. */
export enum PasswordlessSessionState {
  Delivered = 'Delivered',
  Expired = 'Expired',
  New = 'New',
  Pending = 'Pending',
  Verified = 'Verified',
}

export type PeopleHrDirectory = {
  __typename?: 'PeopleHrDirectory';
  apiKey?: Maybe<Scalars['String']>;
};

export type Portal_AddAdpConnectionSslCertificateInput = {
  connectionId: Scalars['String'];
  privateKey: Scalars['String'];
  value: Scalars['String'];
};

export type Portal_AddAdpConnectionSslCertificateResult =
  | ConnectionNotFound
  | Portal_AdpConnectionSslCertificateAdded
  | Portal_InvalidAdpConnectionSslCertificate;

export type Portal_AdpConnectionSslCertificate = {
  __typename?: 'Portal_AdpConnectionSslCertificate';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export type Portal_AdpConnectionSslCertificateAdded = {
  __typename?: 'Portal_AdpConnectionSslCertificateAdded';
  adpConnectionSslCertificate: Portal_AdpConnectionSslCertificate;
};

export type Portal_Connection = {
  __typename?: 'portal_Connection';
  adpConnectionSslCertificates: Array<Portal_AdpConnectionSslCertificate>;
  /** @deprecated Please use `attributeMap` instead. */
  attribute_map?: Maybe<AttributeMap>;
  attributeMap?: Maybe<AttributeMap>;
  connectionDomains: Array<ConnectionDomain>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  oauthSessions: OAuthSessionsList;
  oidc_client_id?: Maybe<Scalars['String']>;
  oidc_client_secret?: Maybe<Scalars['String']>;
  oidc_discovery_endpoint?: Maybe<Scalars['String']>;
  oidc_redirect_uri?: Maybe<Scalars['String']>;
  oidcSessions: OidcSessionsList;
  organization?: Maybe<Organization>;
  organizationDomains: Array<OrganizationDomain>;
  saml: Portal_SamlConnection;
  saml_acs_url: Scalars['String'];
  saml_entity_id?: Maybe<Scalars['String']>;
  saml_idp_metadata_url?: Maybe<Scalars['String']>;
  saml_idp_url?: Maybe<Scalars['String']>;
  saml_sp_metadata_url: Scalars['String'];
  /** @deprecated Please use `samlX509Certificates` instead. */
  saml_x509_certs?: Maybe<Array<Scalars['String']>>;
  samlSessions: SamlSessionsList;
  samlX509Certificates: Array<Portal_SamlX509Certificate>;
  state: ConnectionState;
  /** @deprecated Please use `state` instead. */
  status: ConnectionStatus;
  type: ConnectionType;
};

export type Portal_ConnectionOauthSessionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<OAuthSessionState>;
};

export type Portal_ConnectionOidcSessionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<OidcSessionState>;
};

export type Portal_ConnectionSamlSessionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<SamlSessionState>;
};

export type Portal_ConnectionList = {
  __typename?: 'portal_ConnectionList';
  data: Array<Portal_Connection>;
  listMetadata: ListMetadata;
};

export type Portal_ConnectionUpdated = {
  __typename?: 'Portal_ConnectionUpdated';
  connection: Portal_Connection;
};

export type Portal_ConnectionUpdatedFromMetadataUrl = {
  __typename?: 'Portal_ConnectionUpdatedFromMetadataUrl';
  connection: Portal_Connection;
};

export type Portal_ConnectionUpdatedFromMetadataXml = {
  __typename?: 'Portal_ConnectionUpdatedFromMetadataXml';
  connection: Portal_Connection;
};

export type Portal_DeleteDirectoryInput = {
  id: Scalars['String'];
};

export type Portal_DeleteDirectoryPayload = {
  __typename?: 'Portal_DeleteDirectoryPayload';
  directory?: Maybe<Portal_Directory>;
};

export type Portal_DeleteSamlX509CertificateInput = {
  samlX509CertificateId: Scalars['String'];
};

export type Portal_DeleteSamlX509CertificateResult =
  | Portal_SamlX509CertificateDeleted
  | SamlX509CertificateNotFound;

export type Portal_Directory = {
  __typename?: 'portal_Directory';
  attributeMap?: Maybe<DirectoryAttributeMap>;
  bamboo_hr_api_key?: Maybe<Scalars['String']>;
  bamboo_hr_subdomain?: Maybe<Scalars['String']>;
  /** @deprecated Please use `bearerToken` instead */
  bearer_token?: Maybe<Scalars['String']>;
  bearerToken?: Maybe<Scalars['String']>;
  bob_api_token?: Maybe<Scalars['String']>;
  bob_service_user_id?: Maybe<Scalars['String']>;
  breatheHrApiKey?: Maybe<Scalars['String']>;
  countsSummary: Portal_DirectoryCountsSummary;
  createdAt: Scalars['DateTime'];
  customAttributeMappings: Array<Portal_DirectoryCustomAttributeMapping>;
  directorySyncRuns: Portal_DirectorySyncRunsList;
  directoryUser: DirectoryUser;
  directoryUsers: DirectoryUsersList;
  endpoint?: Maybe<Scalars['String']>;
  environment: Environment;
  fourthHrOrganizationId?: Maybe<Scalars['String']>;
  fourthHrPassword?: Maybe<Scalars['String']>;
  fourthHrUsername?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organization?: Maybe<Organization>;
  peopleHrApiKey?: Maybe<Scalars['String']>;
  rippling_api_key?: Maybe<Scalars['String']>;
  setup_url?: Maybe<Scalars['String']>;
  state: DirectoryState;
  type: DirectoryType;
  updatedAt: Scalars['DateTime'];
  workday_raas_endpoint?: Maybe<Scalars['String']>;
  workday_raas_group_endpoint?: Maybe<Scalars['String']>;
  workday_raas_password?: Maybe<Scalars['String']>;
  workday_raas_username?: Maybe<Scalars['String']>;
};

export type Portal_DirectoryDirectorySyncRunsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<DirectorySyncRunState>;
};

export type Portal_DirectoryDirectoryUserArgs = {
  id: Scalars['String'];
};

export type Portal_DirectoryDirectoryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type Portal_DirectoryCountsSummary = {
  __typename?: 'Portal_DirectoryCountsSummary';
  groupCount: Scalars['Int'];
  userCount: Scalars['Int'];
};

export type Portal_DirectoryCustomAttribute = {
  __typename?: 'Portal_DirectoryCustomAttribute';
  id: Scalars['ID'];
  isRequired: Scalars['Boolean'];
  name: Scalars['String'];
};

export type Portal_DirectoryCustomAttributeList = {
  __typename?: 'Portal_DirectoryCustomAttributeList';
  data: Array<Portal_DirectoryCustomAttribute>;
};

export type Portal_DirectoryCustomAttributeMapping = {
  __typename?: 'Portal_DirectoryCustomAttributeMapping';
  attribute: Scalars['String'];
  customAttributeId: Scalars['ID'];
  id: Scalars['ID'];
};

export type Portal_DirectoryCustomAttributeMappingInput = {
  attribute: Scalars['String'];
  customAttributeId: Scalars['ID'];
};

export type Portal_DirectoryCustomAttributeMappingsSet = {
  __typename?: 'Portal_DirectoryCustomAttributeMappingsSet';
  mappings: Array<Portal_DirectoryCustomAttributeMapping>;
};

export type Portal_DirectoryList = {
  __typename?: 'portal_DirectoryList';
  data: Array<Portal_Directory>;
  listMetadata: ListMetadata;
};

export type Portal_DirectorySyncRun = {
  __typename?: 'Portal_DirectorySyncRun';
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  directoryId: Scalars['String'];
  errors: Array<DirectorySyncRunError>;
  groupCounts: Portal_DirectorySyncRunResourceCounts;
  id: Scalars['String'];
  state: DirectorySyncRunState;
  updatedAt: Scalars['DateTime'];
  userCounts: Portal_DirectorySyncRunResourceCounts;
};

export type Portal_DirectorySyncRunResourceCounts = {
  __typename?: 'Portal_DirectorySyncRunResourceCounts';
  created: Scalars['Float'];
  deleted: Scalars['Float'];
  processed: Scalars['Float'];
  updated: Scalars['Float'];
};

export type Portal_DirectorySyncRunsList = {
  __typename?: 'portal_DirectorySyncRunsList';
  data: Array<Portal_DirectorySyncRun>;
  listMetadata: ListMetadata;
};

export type Portal_InvalidAdpConnectionSslCertificate = {
  __typename?: 'Portal_InvalidAdpConnectionSslCertificate';
  privateKey: Scalars['String'];
  value: Scalars['String'];
};

export type Portal_MetadataFetchFailed = {
  __typename?: 'Portal_MetadataFetchFailed';
  reason: Scalars['String'];
};

export type Portal_MetadataParseFailed = {
  __typename?: 'Portal_MetadataParseFailed';
  reason: Scalars['String'];
};

export type Portal_PortalSession = {
  __typename?: 'portal_PortalSession';
  appName: Scalars['String'];
  connection?: Maybe<Portal_Connection>;
  directory?: Maybe<Portal_Directory>;
  id: Scalars['ID'];
  organization: Organization;
  portalSettings: PortalSettings;
  returnUrl: Scalars['String'];
  state: PortalSessionState;
};

export type Portal_SamlConnection = {
  __typename?: 'Portal_SamlConnection';
  idpEntityId?: Maybe<Scalars['String']>;
};

export type Portal_SamlX509Certificate = {
  __typename?: 'portal_SamlX509Certificate';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  notAfter?: Maybe<Scalars['DateTime']>;
  notBefore?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export type Portal_SamlX509CertificateDeleted = {
  __typename?: 'Portal_SamlX509CertificateDeleted';
  samlX509Certificate: Portal_SamlX509Certificate;
};

export type Portal_SetDirectoryCustomAttributeMappingsInput = {
  directoryId: Scalars['ID'];
  mappings: Array<Portal_DirectoryCustomAttributeMappingInput>;
};

export type Portal_SetDirectoryCustomAttributeMappingsResult =
  | DirectoryCustomAttributeNotFound
  | DirectoryNotFound
  | Portal_DirectoryCustomAttributeMappingsSet;

export type Portal_UpdateConnectionFromMetadataUrlInput = {
  connectionId: Scalars['String'];
  metadataUrl: Scalars['String'];
};

export type Portal_UpdateConnectionFromMetadataUrlResult =
  | ConnectionNotFound
  | InvalidSamlX509Certificate
  | Portal_ConnectionUpdatedFromMetadataUrl
  | Portal_MetadataFetchFailed;

export type Portal_UpdateConnectionFromMetadataXmlInput = {
  connectionId: Scalars['String'];
  metadataXml: Scalars['String'];
};

export type Portal_UpdateConnectionFromMetadataXmlResult =
  | ConnectionNotFound
  | InvalidSamlX509Certificate
  | Portal_ConnectionUpdatedFromMetadataXml
  | Portal_MetadataParseFailed;

export type Portal_UpdateConnectionInput = {
  connectionId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  oidc?: InputMaybe<Portal_UpdateOidcConnectionInput>;
  saml?: InputMaybe<Portal_UpdateSamlConnectionInput>;
};

export type Portal_UpdateConnectionResult =
  | ConnectionNotFound
  | InvalidSamlX509Certificate
  | Portal_ConnectionUpdated
  | VerifyConnectionFailed;

export type Portal_UpdateDirectoryAttributeMapInput = {
  directoryAttributeMapId: Scalars['String'];
  emailsAttribute?: InputMaybe<Scalars['String']>;
  externalIdAttribute?: InputMaybe<Scalars['String']>;
  firstNameAttribute?: InputMaybe<Scalars['String']>;
  groupNameAttribute?: InputMaybe<Scalars['String']>;
  lastNameAttribute?: InputMaybe<Scalars['String']>;
  usernameAttribute?: InputMaybe<Scalars['String']>;
};

export type Portal_UpdateDirectoryAttributeMapPayload = {
  __typename?: 'Portal_UpdateDirectoryAttributeMapPayload';
  directoryAttributeMap?: Maybe<DirectoryAttributeMap>;
};

export type Portal_UpdateOidcConnectionInput = {
  clientId?: InputMaybe<Scalars['String']>;
  clientSecret?: InputMaybe<Scalars['String']>;
  discoveryEndpoint?: InputMaybe<Scalars['String']>;
};

export type Portal_UpdateSamlConnectionInput = {
  idpEntityId?: InputMaybe<Scalars['String']>;
  idpMetadataUrl?: InputMaybe<Scalars['String']>;
  idpUrl?: InputMaybe<Scalars['String']>;
  x509Certs?: InputMaybe<Array<Scalars['String']>>;
};

export type PortalLinkGenerated = {
  __typename?: 'PortalLinkGenerated';
  portalLink: Scalars['String'];
};

/** Enum represents the state of an Admin Portal session. */
export enum PortalSessionState {
  Exchanged = 'Exchanged',
  Pending = 'Pending',
}

export type PortalSettings = {
  __typename?: 'PortalSettings';
  defaultRedirectLink: Scalars['String'];
  faviconUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  logoUrl?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
};

export type PortalSetupLink = {
  __typename?: 'PortalSetupLink';
  expiresAt: Scalars['DateTime'];
  id: Scalars['ID'];
  intent: PortalSetupLinkIntent;
  state: PortalSetupLinkState;
  token: Scalars['String'];
  url: Scalars['String'];
};

export type PortalSetupLinkGenerated = {
  __typename?: 'PortalSetupLinkGenerated';
  portalSetupLink: PortalSetupLink;
};

/** Enum represents the intent for a given of an Admin Portal setup link. */
export enum PortalSetupLinkIntent {
  Dsync = 'Dsync',
  Sso = 'Sso',
}

export type PortalSetupLinkList = {
  __typename?: 'PortalSetupLinkList';
  data: Array<PortalSetupLink>;
};

/** Enum represents the state of an Admin Portal setup link. */
export enum PortalSetupLinkState {
  Active = 'Active',
  Inactive = 'Inactive',
}

export type Profile = {
  __typename?: 'Profile';
  /** @deprecated Please use `connectionType` instead. */
  connection_type: ConnectionType;
  connectionType: ConnectionType;
  email?: Maybe<Scalars['String']>;
  /** @deprecated Please use `firstName` instead. */
  first_name?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** @deprecated Please use `idpId` instead. */
  idp_id?: Maybe<Scalars['String']>;
  idpId?: Maybe<Scalars['String']>;
  /** @deprecated Please use `lastName` instead. */
  last_name?: Maybe<Scalars['String']>;
  lastLoginAt?: Maybe<Scalars['DateTime']>;
  lastName?: Maybe<Scalars['String']>;
  /** @deprecated Please use `rawAttributes` instead. */
  raw_attributes?: Maybe<Scalars['JSON']>;
  rawAttributes?: Maybe<Scalars['JSON']>;
  state: ProfileState;
  updatedAt: Scalars['DateTime'];
};

export type ProfileByIdpIdInput = {
  connectionId: Scalars['String'];
  idpId: Scalars['String'];
};

export type ProfileNotAllowedOutsideOrganizationError = {
  __typename?: 'ProfileNotAllowedOutsideOrganizationError';
  code: ConnectionSessionErrorCode;
  organizationDomains: Array<Scalars['String']>;
  profileDomain: Scalars['String'];
  reason: Scalars['String'];
};

/** Enum represents the state of the profile. */
export enum ProfileState {
  Invalid = 'Invalid',
  Valid = 'Valid',
}

export type ProjectV2 = {
  __typename?: 'ProjectV2';
  environments: Array<Environment>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ProvisionTestAppAndUserInput = {
  environmentId: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  activePortalSetupLinks: PortalSetupLinkList;
  connection: Connection;
  connections: ConnectionList;
  currentEnvironment: Environment;
  currentTeam?: Maybe<Team>;
  directories: DirectoryList;
  directory: Directory;
  directoryAttributeMap: DirectoryAttributeMap;
  directoryCustomAttributeMappings: DirectoryCustomAttributeMappingList;
  directoryCustomAttributes: DirectoryCustomAttributeList;
  directorySyncRun: DirectorySyncRun;
  directoryUser: DirectoryUser;
  emailNotificationPreferences: EmailNotificationPreferences;
  environment: Environment;
  event: Event;
  eventActions: Array<EventAction>;
  events: EventList;
  generateEventsCSV: Scalars['String'];
  keys: KeyList;
  me: User;
  oauthCredentialsByType: OAuthCredentialList;
  organization: Organization;
  organizations: OrganizationList;
  passwordlessSessions: PasswordlessSessionList;
  portal_connections: Portal_ConnectionList;
  portal_connectionSession: ConnectionSession;
  portal_directories: Portal_DirectoryList;
  portal_directory: Portal_Directory;
  portal_directoryCustomAttributes: Portal_DirectoryCustomAttributeList;
  portal_portalSession: Portal_PortalSession;
  profile: Profile;
  profileByIdpId: Profile;
  /** @deprecated Please use `profileByIdpId` instead. */
  profileForIdpId: Profile;
  redirectUris: RedirectUriList;
  request: Request;
  requests: RequestList;
  session: Session;
  webhook: Webhook;
  webhookEndpoint: WebhookEndpoint;
  webhookEndpoints: WebhookEndpointList;
  webhookEvents: Array<WebhookEvent>;
};

export type QueryActivePortalSetupLinksArgs = {
  organizationId: Scalars['String'];
};

export type QueryConnectionArgs = {
  id: Scalars['String'];
};

export type QueryConnectionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  organizationId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
};

export type QueryDirectoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  domain?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  organizationId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
};

export type QueryDirectoryArgs = {
  id: Scalars['String'];
};

export type QueryDirectoryAttributeMapArgs = {
  id: Scalars['String'];
};

export type QueryDirectoryCustomAttributeMappingsArgs = {
  directoryId: Scalars['String'];
};

export type QueryDirectoryCustomAttributesArgs = {
  environmentId: Scalars['String'];
};

export type QueryDirectorySyncRunArgs = {
  id: Scalars['String'];
};

export type QueryDirectoryUserArgs = {
  id: Scalars['String'];
};

export type QueryEnvironmentArgs = {
  id: Scalars['String'];
};

export type QueryEventArgs = {
  id: Scalars['String'];
};

export type QueryEventActionsArgs = {
  environmentId: Scalars['String'];
};

export type QueryEventsArgs = {
  action?: InputMaybe<Array<Scalars['String']>>;
  action_type?: InputMaybe<Array<Scalars['String']>>;
  actor_id?: InputMaybe<Array<Scalars['String']>>;
  actor_name?: InputMaybe<Array<Scalars['String']>>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  environmentId: Scalars['String'];
  group?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  occurred_at?: InputMaybe<Scalars['DateTime']>;
  occurred_at_gt?: InputMaybe<Scalars['DateTime']>;
  occurred_at_gte?: InputMaybe<Scalars['DateTime']>;
  occurred_at_lt?: InputMaybe<Scalars['DateTime']>;
  occurred_at_lte?: InputMaybe<Scalars['DateTime']>;
  search?: InputMaybe<Scalars['String']>;
  target_id?: InputMaybe<Array<Scalars['String']>>;
  target_name?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryGenerateEventsCsvArgs = {
  action?: InputMaybe<Array<Scalars['String']>>;
  action_type?: InputMaybe<Array<Scalars['String']>>;
  actor_id?: InputMaybe<Array<Scalars['String']>>;
  actor_name?: InputMaybe<Array<Scalars['String']>>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  environmentId: Scalars['String'];
  group?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  occurred_at?: InputMaybe<Scalars['DateTime']>;
  occurred_at_gt?: InputMaybe<Scalars['DateTime']>;
  occurred_at_gte?: InputMaybe<Scalars['DateTime']>;
  occurred_at_lt?: InputMaybe<Scalars['DateTime']>;
  occurred_at_lte?: InputMaybe<Scalars['DateTime']>;
  search?: InputMaybe<Scalars['String']>;
  target_id?: InputMaybe<Array<Scalars['String']>>;
  target_name?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryKeysArgs = {
  environmentId: Scalars['String'];
  scope?: InputMaybe<KeyScope>;
};

export type QueryOauthCredentialsByTypeArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  environmentId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  type: ConnectionType;
};

export type QueryOrganizationArgs = {
  id: Scalars['String'];
};

export type QueryOrganizationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  domains?: InputMaybe<Array<Scalars['String']>>;
  environmentId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};

export type QueryPasswordlessSessionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  connectionId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
};

export type QueryPortal_ConnectionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};

export type QueryPortal_ConnectionSessionArgs = {
  id: Scalars['String'];
};

export type QueryPortal_DirectoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type QueryPortal_DirectoryArgs = {
  id: Scalars['String'];
};

export type QueryPortal_DirectoryCustomAttributesArgs = {
  directoryId: Scalars['String'];
};

export type QueryProfileArgs = {
  id: Scalars['String'];
};

export type QueryProfileByIdpIdArgs = {
  input: ProfileByIdpIdInput;
};

export type QueryProfileForIdpIdArgs = {
  idp_id: Scalars['String'];
  project_id: Scalars['String'];
};

export type QueryRedirectUrisArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  environmentId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
};

export type QueryRequestArgs = {
  id: Scalars['String'];
};

export type QueryRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['DateTime']>;
  created_at_gt?: InputMaybe<Scalars['DateTime']>;
  created_at_gte?: InputMaybe<Scalars['DateTime']>;
  created_at_lt?: InputMaybe<Scalars['DateTime']>;
  created_at_lte?: InputMaybe<Scalars['DateTime']>;
  environmentId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  method?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
};

export type QueryWebhookArgs = {
  id: Scalars['String'];
};

export type QueryWebhookEndpointArgs = {
  id: Scalars['String'];
};

export type QueryWebhookEndpointsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  environmentId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
};

export type ReceivedAttribute = {
  __typename?: 'ReceivedAttribute';
  attribute: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type RedirectUri = {
  __typename?: 'RedirectURI';
  /** @deprecated Please use `isDefault` instead. */
  default: Scalars['Boolean'];
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  uri: Scalars['String'];
};

export type RedirectUriList = {
  __typename?: 'RedirectUriList';
  data: Array<RedirectUri>;
  listMetadata: ListMetadata;
};

export type RedirectUrisSet = {
  __typename?: 'RedirectUrisSet';
  redirectUris: Array<RedirectUri>;
};

export type Request = {
  __typename?: 'Request';
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  method: Scalars['String'];
  path: Scalars['String'];
  /** @deprecated Please use `requestBody` instead. */
  request_body?: Maybe<Scalars['JSON']>;
  /** @deprecated Please use `requestHeaders` instead. */
  request_headers: Scalars['JSON'];
  /** @deprecated Please use `requestId` instead. */
  request_id?: Maybe<Scalars['String']>;
  /** @deprecated Please use `requestQuery` instead. */
  request_query?: Maybe<Scalars['JSON']>;
  requestBody?: Maybe<Scalars['JSON']>;
  requestHeaders: Scalars['JSON'];
  requestId?: Maybe<Scalars['String']>;
  requestQuery?: Maybe<Scalars['JSON']>;
  /** @deprecated Please use `responseBody` instead. */
  response_body?: Maybe<Scalars['JSON']>;
  responseBody?: Maybe<Scalars['JSON']>;
  source?: Maybe<Scalars['String']>;
  status: Scalars['Float'];
};

export type RequestList = {
  __typename?: 'RequestList';
  data: Array<Request>;
  listMetadata: ListMetadata;
};

export type RipplingDirectory = {
  __typename?: 'RipplingDirectory';
  apiKey?: Maybe<Scalars['String']>;
};

export type SamlConnection = {
  __typename?: 'SamlConnection';
  idpEntityId?: Maybe<Scalars['String']>;
};

export type SamlSession = {
  __typename?: 'SAMLSession';
  createdAt: Scalars['DateTime'];
  errors: Array<ConnectionSessionError>;
  id: Scalars['ID'];
  profile?: Maybe<Profile>;
  /** @deprecated Please use `samlRequest` instead. */
  saml_request?: Maybe<Scalars['String']>;
  /** @deprecated Please use `samlResponse` instead. */
  saml_response?: Maybe<Scalars['String']>;
  samlRequest?: Maybe<Scalars['String']>;
  samlResponse?: Maybe<Scalars['String']>;
  state: SamlSessionState;
  /** @deprecated Please use `stateParameter` instead. */
  state_parameter: Scalars['String'];
  stateParameter: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type SamlSessionsList = {
  __typename?: 'SAMLSessionsList';
  data: Array<SamlSession>;
  listMetadata: ListMetadata;
};

/** Enum represents the state of the SAML Session. */
export enum SamlSessionState {
  Authorized = 'Authorized',
  Failed = 'Failed',
  Started = 'Started',
  Successful = 'Successful',
}

export type SamlX509Certificate = {
  __typename?: 'SamlX509Certificate';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  notAfter?: Maybe<Scalars['DateTime']>;
  notBefore?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export type SamlX509CertificateDeleted = {
  __typename?: 'SamlX509CertificateDeleted';
  samlX509Certificate: SamlX509Certificate;
};

export type SamlX509CertificateNotFound = {
  __typename?: 'SamlX509CertificateNotFound';
  samlX509CertificateId: Scalars['String'];
};

export type SamlX509CertificateUpserted = {
  __typename?: 'SamlX509CertificateUpserted';
  samlX509Certificate: SamlX509Certificate;
};

/** Enum represents why the SAML X509 Certificate was not valid. */
export enum SamlX509CertificateValidationErrorCode {
  ExpiredCertificate = 'ExpiredCertificate',
  MalformedCertificate = 'MalformedCertificate',
}

export type Session = {
  __typename?: 'Session';
  currentEnvironment: Environment;
};

export type SetDirectoryCustomAttributeMappingsInput = {
  directoryId: Scalars['ID'];
  mappings: Array<DirectoryCustomAttributeMappingInput>;
};

export type SetDirectoryCustomAttributeMappingsResult =
  | DirectoryCustomAttributeMappingsSet
  | DirectoryCustomAttributeNotFound
  | DirectoryNotFound;

export type SetDomainsForOrganizationInput = {
  domains: Array<Scalars['String']>;
  organizationId: Scalars['String'];
};

export type SetDomainsForOrganizationPayload = {
  __typename?: 'SetDomainsForOrganizationPayload';
  organization?: Maybe<Organization>;
};

export type SetEmailNotificationPreferenceInput = {
  category: EmailNotificationCategory;
  preference: EmailNotificationPreferenceConfiguration;
};

export type SetEmailNotificationPreferencesInput = {
  preferences: Array<SetEmailNotificationPreferenceInput>;
};

export type SetEmailNotificationPreferencesResult =
  EmailNotificationPreferences;

export type SetRedirectUriInput = {
  id?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  uri: Scalars['String'];
};

export type SetRedirectUrisInput = {
  environmentId: Scalars['String'];
  redirectUris: Array<SetRedirectUriInput>;
};

export type SetRedirectUrisResult =
  | InvalidRedirectUriProcotol
  | RedirectUrisSet;

export type SsoAlreadyConnectedForDomain = {
  __typename?: 'SsoAlreadyConnectedForDomain';
  domain: Scalars['String'];
};

export type SsoDemoApplication = {
  __typename?: 'SSODemoApplication';
  connection?: Maybe<Connection>;
  /** @deprecated Please use `connectionType` instead. */
  connection_type: ConnectionType;
  connectionType: ConnectionType;
  id: Scalars['ID'];
  /** @deprecated Please use `idpId` instead. */
  idp_id?: Maybe<Scalars['String']>;
  idpId?: Maybe<Scalars['String']>;
};

export type SsoDemoUser = {
  __typename?: 'SSODemoUser';
  /** @deprecated Please use `connectionType` instead. */
  connection_type: ConnectionType;
  connectionType: ConnectionType;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** @deprecated Please use `idpId` instead. */
  idp_id?: Maybe<Scalars['String']>;
  idpId?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type SuccessResponse = {
  __typename?: 'SuccessResponse';
  success: Scalars['Boolean'];
};

export type Team = {
  __typename?: 'Team';
  billingDetails: TeamBillingDetails;
  connection?: Maybe<Connection>;
  endpoint_url?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  onboarding: TeamOnboardingStatus;
  projectsV2: Array<ProjectV2>;
  sdk_language?: Maybe<Scalars['String']>;
  sso_onboarding_step?: Maybe<Scalars['Int']>;
  ssoDemoUser?: Maybe<SsoDemoUser>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  users_teams: Array<UsersTeam>;
};

export type TeamBillingDetails = {
  __typename?: 'TeamBillingDetails';
  connections: TeamConnectionStats;
  email?: Maybe<Scalars['String']>;
  /** Whether the team has a payment method setup. */
  hasPaymentMethod: Scalars['Boolean'];
};

export type TeamConnectionStats = {
  __typename?: 'TeamConnectionStats';
  enterpriseConnectionCount: Scalars['Float'];
};

export type TeamOnboarded = {
  __typename?: 'TeamOnboarded';
  status: Scalars['String'];
};

export type TeamOnboarding = {
  __typename?: 'TeamOnboarding';
  /** The next onboarding step the team needs to go through. */
  nextStep: Scalars['String'];
  status: Scalars['String'];
};

export type TeamOnboardingStatus = TeamOnboarded | TeamOnboarding;

/** Enum represents the login provider for the team. */
export enum TeamsProvider {
  Email = 'Email',
  Google = 'Google',
}

export type UpdateBambooHrDirectoryInput = {
  apiKey?: InputMaybe<Scalars['String']>;
  subdomain?: InputMaybe<Scalars['String']>;
};

export type UpdateBreatheHrDirectoryInput = {
  apiKey?: InputMaybe<Scalars['String']>;
};

export type UpdateConnectionFromMetadataUrlInput = {
  connectionId: Scalars['String'];
  metadataUrl: Scalars['String'];
};

export type UpdateConnectionFromMetadataUrlResult =
  | ConnectionNotFound
  | ConnectionUpdatedFromMetadataUrl
  | InvalidSamlX509Certificate
  | MetadataFetchFailed;

export type UpdateConnectionFromMetadataXmlInput = {
  connectionId: Scalars['String'];
  metadataXml: Scalars['String'];
};

export type UpdateConnectionFromMetadataXmlResult =
  | ConnectionNotFound
  | ConnectionUpdatedFromMetadataXml
  | InvalidSamlX509Certificate
  | MetadataParseFailed;

export type UpdateConnectionInput = {
  connectionId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  oidc?: InputMaybe<UpdateOidcConnectionInput>;
  saml?: InputMaybe<UpdateSamlConnectionInput>;
};

export type UpdateConnectionResult =
  | ConnectionNotFound
  | ConnectionUpdated
  | InvalidSamlX509Certificate;

export type UpdateDirectoryCustomAttributeInput = {
  directoryCustomAttributeId: Scalars['ID'];
  isRequired?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateDirectoryCustomAttributeResult =
  | DirectoryCustomAttributeNotFound
  | DirectoryCustomAttributeUpdated;

export type UpdateDirectoryInput = {
  bambooHr?: InputMaybe<UpdateBambooHrDirectoryInput>;
  breatheHr?: InputMaybe<UpdateBreatheHrDirectoryInput>;
  directoryId: Scalars['String'];
  fourthHr?: InputMaybe<UpdateFourthHrDirectoryInput>;
  hibob?: InputMaybe<UpdateHibobDirectoryInput>;
  name?: InputMaybe<Scalars['String']>;
  peopleHr?: InputMaybe<UpdatePeopleHrDirectoryInput>;
  rippling?: InputMaybe<UpdateRipplingDirectoryInput>;
  workday?: InputMaybe<UpdateWorkdayDirectoryInput>;
};

export type UpdateDirectoryResult =
  | DirectoryNotFound
  | DirectoryProviderErrors
  | DirectoryUpdated;

export type UpdateEnvironmentInput = {
  allowProfilesOutsideOrganization?: InputMaybe<Scalars['Boolean']>;
  environmentId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateEnvironmentResult = EnvironmentNotFound | EnvironmentUpdated;

export type UpdateFourthHrDirectoryInput = {
  organizationId?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type UpdateHibobDirectoryInput = {
  apiToken?: InputMaybe<Scalars['String']>;
  serviceUserId?: InputMaybe<Scalars['String']>;
};

export type UpdateOidcConnectionInput = {
  clientId?: InputMaybe<Scalars['String']>;
  clientSecret?: InputMaybe<Scalars['String']>;
  discoveryEndpoint?: InputMaybe<Scalars['String']>;
};

export type UpdateOrganizationPayload = {
  __typename?: 'UpdateOrganizationPayload';
  organization?: Maybe<Organization>;
};

export type UpdatePeopleHrDirectoryInput = {
  apiKey?: InputMaybe<Scalars['String']>;
};

export type UpdateRipplingDirectoryInput = {
  apiKey?: InputMaybe<Scalars['String']>;
};

export type UpdateSamlConnectionInput = {
  idpEntityId?: InputMaybe<Scalars['String']>;
  idpMetadataUrl?: InputMaybe<Scalars['String']>;
  idpUrl?: InputMaybe<Scalars['String']>;
  x509Certs?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateSessionInput = {
  currentEnvironmentId?: InputMaybe<Scalars['String']>;
  currentProjectID?: InputMaybe<Scalars['String']>;
};

export type UpdateWorkdayDirectoryInput = {
  groupEndpoint?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  userEndpoint?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type UpsertSamlX509CertificateInput = {
  connectionId: Scalars['String'];
  value: Scalars['String'];
};

export type UpsertSamlX509CertificateResult =
  | ConnectionNotFound
  | SamlX509CertificateUpserted;

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  users_teams: Array<UsersTeam>;
};

export type UserInviteInput = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  role: UsersOrganizationsRole;
};

export type UserNotAuthorized = {
  __typename?: 'UserNotAuthorized';
  user: User;
};

/** Roles a user can have in an organization. */
export enum UsersOrganizationsRole {
  Admin = 'ADMIN',
  Member = 'MEMBER',
}

/** States a user can have in an organization. */
export enum UsersOrganizationsState {
  Accepted = 'ACCEPTED',
  Invited = 'INVITED',
}

export type UsersTeam = {
  __typename?: 'UsersTeam';
  id: Scalars['ID'];
  role: UsersOrganizationsRole;
  state: UsersOrganizationsState;
  team: Team;
  user: User;
  user_id: Scalars['String'];
};

export type VerifyConnectionFailed = {
  __typename?: 'VerifyConnectionFailed';
  connectionErrors: Array<ConnectionError>;
};

export type Webhook = {
  __typename?: 'Webhook';
  attempt: Scalars['Int'];
  /** @deprecated Please use `deliverAfter` instead. */
  deliver_after?: Maybe<Scalars['DateTime']>;
  deliverAfter?: Maybe<Scalars['DateTime']>;
  event: Scalars['String'];
  id: Scalars['ID'];
  metadata: Scalars['JSON'];
  request_body?: Maybe<Scalars['JSON']>;
  /** @deprecated Please use `requestBody` instead. */
  requestBody?: Maybe<Scalars['JSON']>;
  /** @deprecated Please use `responseBody` instead. */
  response_body?: Maybe<Scalars['JSON']>;
  responseBody?: Maybe<Scalars['JSON']>;
  state: WebhookState;
  /** @deprecated Please use `webhookEndpoint.id` instead. */
  webhook_endpoint_id: Scalars['String'];
};

export type WebhookEndpoint = {
  __typename?: 'WebhookEndpoint';
  createdAt: Scalars['DateTime'];
  /** @deprecated Please use `endpointUrl` instead. */
  endpoint_url: Scalars['String'];
  endpointUrl: Scalars['String'];
  events: Array<Scalars['String']>;
  id: Scalars['ID'];
  secret: Scalars['String'];
  state: WebhookEndpointState;
  webhooks: WebhookList;
};

export type WebhookEndpointWebhooksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};

export type WebhookEndpointList = {
  __typename?: 'WebhookEndpointList';
  data: Array<WebhookEndpoint>;
  listMetadata: ListMetadata;
};

/** Enum represents the state of the webhook endpoint. */
export enum WebhookEndpointState {
  Active = 'Active',
  InActive = 'InActive',
}

export type WebhookEvent = {
  __typename?: 'WebhookEvent';
  description: Scalars['String'];
  fixtures: Array<WebhookFixture>;
  id: Scalars['ID'];
};

/** Enum represents event fixtures of Webhooks */
export enum WebhookEventFixtures {
  ConnectionActivated = 'ConnectionActivated',
  ConnectionDeactivated = 'ConnectionDeactivated',
  ConnectionDeleted = 'ConnectionDeleted',
  ConnectionUpdatedLinked = 'ConnectionUpdatedLinked',
  ConnectionUpdatedUnlinked = 'ConnectionUpdatedUnlinked',
  DirectoryActivated = 'DirectoryActivated',
  DirectoryDeactivated = 'DirectoryDeactivated',
  DirectoryDeleted = 'DirectoryDeleted',
  GroupCreated = 'GroupCreated',
  GroupDeleted = 'GroupDeleted',
  GroupUpdated = 'GroupUpdated',
  UserAddedToGroup = 'UserAddedToGroup',
  UserCreated = 'UserCreated',
  UserDeleted = 'UserDeleted',
  UserRemovedFromGroup = 'UserRemovedFromGroup',
  UserUpdated = 'UserUpdated',
}

export type WebhookFixture = {
  __typename?: 'WebhookFixture';
  name: Scalars['String'];
  payload: Scalars['JSON'];
};

export type WebhookList = {
  __typename?: 'WebhookList';
  data: Array<Webhook>;
  listMetadata: ListMetadata;
};

/** Enum represents the state of the webhook. */
export enum WebhookState {
  Canceled = 'Canceled',
  Delivered = 'Delivered',
  Failed = 'Failed',
  InProgress = 'InProgress',
  Scheduled = 'Scheduled',
}

export type WorkdayDirectory = {
  __typename?: 'WorkdayDirectory';
  groupEndpoint?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  userEndpoint?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type AddAdpConnectionSslCertificateMutationVariables = Exact<{
  input: Portal_AddAdpConnectionSslCertificateInput;
}>;

export type AddAdpConnectionSslCertificateMutation = {
  __typename?: 'Mutation';
  portal_addAdpConnectionSslCertificate:
    | { __typename: 'ConnectionNotFound'; connectionId: string }
    | {
        __typename: 'Portal_AdpConnectionSslCertificateAdded';
        adpConnectionSslCertificate: {
          __typename?: 'Portal_AdpConnectionSslCertificate';
          id: string;
          value: string;
        };
      }
    | { __typename: 'Portal_InvalidAdpConnectionSslCertificate' };
};

export type ConnectionDomainFragment = {
  __typename?: 'ConnectionDomain';
  id: string;
  domain: string;
};

export type ConnectionSessionContainerFragment = {
  __typename?: 'portal_Connection';
  samlSessions: {
    __typename?: 'SAMLSessionsList';
    data: Array<{
      __typename: 'SAMLSession';
      createdAt: any;
      id: string;
      saml_request?: string | null | undefined;
      saml_response?: string | null | undefined;
      samlSessionState: SamlSessionState;
      profile?:
        | {
            __typename?: 'Profile';
            idp_id?: string | null | undefined;
            email?: string | null | undefined;
            first_name?: string | null | undefined;
            last_name?: string | null | undefined;
          }
        | null
        | undefined;
      errors: Array<
        | {
            __typename: 'DecryptionFailedError';
            code: ConnectionSessionErrorCode;
            reason: string;
          }
        | {
            __typename: 'InvalidAttributesError';
            code: ConnectionSessionErrorCode;
            reason: string;
            received_attributes: Array<{
              __typename?: 'ReceivedAttribute';
              attribute: string;
              value?: string | null | undefined;
            }>;
            expected_attributes: {
              __typename?: 'ExpectedAttributes';
              email_attribute: string;
              first_name_attribute: string;
              idp_id_attribute: string;
              last_name_attribute: string;
            };
          }
        | {
            __typename: 'InvalidX509CertError';
            code: ConnectionSessionErrorCode;
            reason: string;
          }
        | {
            __typename: 'MalformedSamlResponseError';
            code: ConnectionSessionErrorCode;
            reason: string;
          }
        | {
            __typename: 'ProfileNotAllowedOutsideOrganizationError';
            code: ConnectionSessionErrorCode;
            reason: string;
            profileDomain: string;
            organizationDomains: Array<string>;
          }
      >;
    }>;
  };
  oauthSessions: {
    __typename?: 'OAuthSessionsList';
    data: Array<{
      __typename: 'OAuthSession';
      createdAt: any;
      id: string;
      oauthSessionState: OAuthSessionState;
      profile?:
        | {
            __typename?: 'Profile';
            idp_id?: string | null | undefined;
            email?: string | null | undefined;
            first_name?: string | null | undefined;
            last_name?: string | null | undefined;
            rawAttributes?: any | null | undefined;
          }
        | null
        | undefined;
      errors: Array<
        | {
            __typename: 'DecryptionFailedError';
            code: ConnectionSessionErrorCode;
            reason: string;
          }
        | {
            __typename: 'InvalidAttributesError';
            code: ConnectionSessionErrorCode;
            reason: string;
            received_attributes: Array<{
              __typename?: 'ReceivedAttribute';
              attribute: string;
              value?: string | null | undefined;
            }>;
            expected_attributes: {
              __typename?: 'ExpectedAttributes';
              email_attribute: string;
              first_name_attribute: string;
              idp_id_attribute: string;
              last_name_attribute: string;
            };
          }
        | {
            __typename: 'InvalidX509CertError';
            code: ConnectionSessionErrorCode;
            reason: string;
          }
        | {
            __typename: 'MalformedSamlResponseError';
            code: ConnectionSessionErrorCode;
            reason: string;
          }
        | {
            __typename: 'ProfileNotAllowedOutsideOrganizationError';
            code: ConnectionSessionErrorCode;
            reason: string;
            profileDomain: string;
            organizationDomains: Array<string>;
          }
      >;
    }>;
  };
  oidcSessions: {
    __typename?: 'OidcSessionsList';
    data: Array<{
      __typename: 'OidcSession';
      createdAt: any;
      id: string;
      oidcSessionState: OidcSessionState;
      profile?:
        | {
            __typename?: 'Profile';
            idp_id?: string | null | undefined;
            email?: string | null | undefined;
            first_name?: string | null | undefined;
            last_name?: string | null | undefined;
            rawAttributes?: any | null | undefined;
          }
        | null
        | undefined;
      errors: Array<
        | {
            __typename: 'DecryptionFailedError';
            code: ConnectionSessionErrorCode;
            reason: string;
          }
        | {
            __typename: 'InvalidAttributesError';
            code: ConnectionSessionErrorCode;
            reason: string;
            received_attributes: Array<{
              __typename?: 'ReceivedAttribute';
              attribute: string;
              value?: string | null | undefined;
            }>;
            expected_attributes: {
              __typename?: 'ExpectedAttributes';
              email_attribute: string;
              first_name_attribute: string;
              idp_id_attribute: string;
              last_name_attribute: string;
            };
          }
        | {
            __typename: 'InvalidX509CertError';
            code: ConnectionSessionErrorCode;
            reason: string;
          }
        | {
            __typename: 'MalformedSamlResponseError';
            code: ConnectionSessionErrorCode;
            reason: string;
          }
        | {
            __typename: 'ProfileNotAllowedOutsideOrganizationError';
            code: ConnectionSessionErrorCode;
            reason: string;
            profileDomain: string;
            organizationDomains: Array<string>;
          }
      >;
    }>;
  };
};

type ConnectionSessionError_DecryptionFailedError_Fragment = {
  __typename: 'DecryptionFailedError';
  code: ConnectionSessionErrorCode;
  reason: string;
};

type ConnectionSessionError_InvalidAttributesError_Fragment = {
  __typename: 'InvalidAttributesError';
  code: ConnectionSessionErrorCode;
  reason: string;
  received_attributes: Array<{
    __typename?: 'ReceivedAttribute';
    attribute: string;
    value?: string | null | undefined;
  }>;
  expected_attributes: {
    __typename?: 'ExpectedAttributes';
    email_attribute: string;
    first_name_attribute: string;
    idp_id_attribute: string;
    last_name_attribute: string;
  };
};

type ConnectionSessionError_InvalidX509CertError_Fragment = {
  __typename: 'InvalidX509CertError';
  code: ConnectionSessionErrorCode;
  reason: string;
};

type ConnectionSessionError_MalformedSamlResponseError_Fragment = {
  __typename: 'MalformedSamlResponseError';
  code: ConnectionSessionErrorCode;
  reason: string;
};

type ConnectionSessionError_ProfileNotAllowedOutsideOrganizationError_Fragment =
  {
    __typename: 'ProfileNotAllowedOutsideOrganizationError';
    code: ConnectionSessionErrorCode;
    reason: string;
    profileDomain: string;
    organizationDomains: Array<string>;
  };

export type ConnectionSessionErrorFragment =
  | ConnectionSessionError_DecryptionFailedError_Fragment
  | ConnectionSessionError_InvalidAttributesError_Fragment
  | ConnectionSessionError_InvalidX509CertError_Fragment
  | ConnectionSessionError_MalformedSamlResponseError_Fragment
  | ConnectionSessionError_ProfileNotAllowedOutsideOrganizationError_Fragment;

type ConnectionSession_OAuthSession_Fragment = {
  __typename: 'OAuthSession';
  createdAt: any;
  id: string;
  oauthSessionState: OAuthSessionState;
  profile?:
    | {
        __typename?: 'Profile';
        idp_id?: string | null | undefined;
        email?: string | null | undefined;
        first_name?: string | null | undefined;
        last_name?: string | null | undefined;
        rawAttributes?: any | null | undefined;
      }
    | null
    | undefined;
  errors: Array<
    | {
        __typename: 'DecryptionFailedError';
        code: ConnectionSessionErrorCode;
        reason: string;
      }
    | {
        __typename: 'InvalidAttributesError';
        code: ConnectionSessionErrorCode;
        reason: string;
        received_attributes: Array<{
          __typename?: 'ReceivedAttribute';
          attribute: string;
          value?: string | null | undefined;
        }>;
        expected_attributes: {
          __typename?: 'ExpectedAttributes';
          email_attribute: string;
          first_name_attribute: string;
          idp_id_attribute: string;
          last_name_attribute: string;
        };
      }
    | {
        __typename: 'InvalidX509CertError';
        code: ConnectionSessionErrorCode;
        reason: string;
      }
    | {
        __typename: 'MalformedSamlResponseError';
        code: ConnectionSessionErrorCode;
        reason: string;
      }
    | {
        __typename: 'ProfileNotAllowedOutsideOrganizationError';
        code: ConnectionSessionErrorCode;
        reason: string;
        profileDomain: string;
        organizationDomains: Array<string>;
      }
  >;
};

type ConnectionSession_OidcSession_Fragment = {
  __typename: 'OidcSession';
  createdAt: any;
  id: string;
  oidcSessionState: OidcSessionState;
  profile?:
    | {
        __typename?: 'Profile';
        idp_id?: string | null | undefined;
        email?: string | null | undefined;
        first_name?: string | null | undefined;
        last_name?: string | null | undefined;
        rawAttributes?: any | null | undefined;
      }
    | null
    | undefined;
  errors: Array<
    | {
        __typename: 'DecryptionFailedError';
        code: ConnectionSessionErrorCode;
        reason: string;
      }
    | {
        __typename: 'InvalidAttributesError';
        code: ConnectionSessionErrorCode;
        reason: string;
        received_attributes: Array<{
          __typename?: 'ReceivedAttribute';
          attribute: string;
          value?: string | null | undefined;
        }>;
        expected_attributes: {
          __typename?: 'ExpectedAttributes';
          email_attribute: string;
          first_name_attribute: string;
          idp_id_attribute: string;
          last_name_attribute: string;
        };
      }
    | {
        __typename: 'InvalidX509CertError';
        code: ConnectionSessionErrorCode;
        reason: string;
      }
    | {
        __typename: 'MalformedSamlResponseError';
        code: ConnectionSessionErrorCode;
        reason: string;
      }
    | {
        __typename: 'ProfileNotAllowedOutsideOrganizationError';
        code: ConnectionSessionErrorCode;
        reason: string;
        profileDomain: string;
        organizationDomains: Array<string>;
      }
  >;
};

type ConnectionSession_SamlSession_Fragment = {
  __typename: 'SAMLSession';
  createdAt: any;
  id: string;
  saml_request?: string | null | undefined;
  saml_response?: string | null | undefined;
  samlSessionState: SamlSessionState;
  profile?:
    | {
        __typename?: 'Profile';
        idp_id?: string | null | undefined;
        email?: string | null | undefined;
        first_name?: string | null | undefined;
        last_name?: string | null | undefined;
      }
    | null
    | undefined;
  errors: Array<
    | {
        __typename: 'DecryptionFailedError';
        code: ConnectionSessionErrorCode;
        reason: string;
      }
    | {
        __typename: 'InvalidAttributesError';
        code: ConnectionSessionErrorCode;
        reason: string;
        received_attributes: Array<{
          __typename?: 'ReceivedAttribute';
          attribute: string;
          value?: string | null | undefined;
        }>;
        expected_attributes: {
          __typename?: 'ExpectedAttributes';
          email_attribute: string;
          first_name_attribute: string;
          idp_id_attribute: string;
          last_name_attribute: string;
        };
      }
    | {
        __typename: 'InvalidX509CertError';
        code: ConnectionSessionErrorCode;
        reason: string;
      }
    | {
        __typename: 'MalformedSamlResponseError';
        code: ConnectionSessionErrorCode;
        reason: string;
      }
    | {
        __typename: 'ProfileNotAllowedOutsideOrganizationError';
        code: ConnectionSessionErrorCode;
        reason: string;
        profileDomain: string;
        organizationDomains: Array<string>;
      }
  >;
};

export type ConnectionSessionFragment =
  | ConnectionSession_OAuthSession_Fragment
  | ConnectionSession_OidcSession_Fragment
  | ConnectionSession_SamlSession_Fragment;

export type ConnectionSessionQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ConnectionSessionQuery = {
  __typename?: 'Query';
  portal_connectionSession:
    | {
        __typename: 'OAuthSession';
        createdAt: any;
        id: string;
        oauthSessionState: OAuthSessionState;
        profile?:
          | {
              __typename?: 'Profile';
              idp_id?: string | null | undefined;
              email?: string | null | undefined;
              first_name?: string | null | undefined;
              last_name?: string | null | undefined;
              rawAttributes?: any | null | undefined;
            }
          | null
          | undefined;
        errors: Array<
          | {
              __typename: 'DecryptionFailedError';
              code: ConnectionSessionErrorCode;
              reason: string;
            }
          | {
              __typename: 'InvalidAttributesError';
              code: ConnectionSessionErrorCode;
              reason: string;
              received_attributes: Array<{
                __typename?: 'ReceivedAttribute';
                attribute: string;
                value?: string | null | undefined;
              }>;
              expected_attributes: {
                __typename?: 'ExpectedAttributes';
                email_attribute: string;
                first_name_attribute: string;
                idp_id_attribute: string;
                last_name_attribute: string;
              };
            }
          | {
              __typename: 'InvalidX509CertError';
              code: ConnectionSessionErrorCode;
              reason: string;
            }
          | {
              __typename: 'MalformedSamlResponseError';
              code: ConnectionSessionErrorCode;
              reason: string;
            }
          | {
              __typename: 'ProfileNotAllowedOutsideOrganizationError';
              code: ConnectionSessionErrorCode;
              reason: string;
              profileDomain: string;
              organizationDomains: Array<string>;
            }
        >;
      }
    | {
        __typename: 'OidcSession';
        createdAt: any;
        id: string;
        oidcSessionState: OidcSessionState;
        profile?:
          | {
              __typename?: 'Profile';
              idp_id?: string | null | undefined;
              email?: string | null | undefined;
              first_name?: string | null | undefined;
              last_name?: string | null | undefined;
              rawAttributes?: any | null | undefined;
            }
          | null
          | undefined;
        errors: Array<
          | {
              __typename: 'DecryptionFailedError';
              code: ConnectionSessionErrorCode;
              reason: string;
            }
          | {
              __typename: 'InvalidAttributesError';
              code: ConnectionSessionErrorCode;
              reason: string;
              received_attributes: Array<{
                __typename?: 'ReceivedAttribute';
                attribute: string;
                value?: string | null | undefined;
              }>;
              expected_attributes: {
                __typename?: 'ExpectedAttributes';
                email_attribute: string;
                first_name_attribute: string;
                idp_id_attribute: string;
                last_name_attribute: string;
              };
            }
          | {
              __typename: 'InvalidX509CertError';
              code: ConnectionSessionErrorCode;
              reason: string;
            }
          | {
              __typename: 'MalformedSamlResponseError';
              code: ConnectionSessionErrorCode;
              reason: string;
            }
          | {
              __typename: 'ProfileNotAllowedOutsideOrganizationError';
              code: ConnectionSessionErrorCode;
              reason: string;
              profileDomain: string;
              organizationDomains: Array<string>;
            }
        >;
      }
    | {
        __typename: 'SAMLSession';
        createdAt: any;
        id: string;
        saml_request?: string | null | undefined;
        saml_response?: string | null | undefined;
        samlSessionState: SamlSessionState;
        profile?:
          | {
              __typename?: 'Profile';
              idp_id?: string | null | undefined;
              email?: string | null | undefined;
              first_name?: string | null | undefined;
              last_name?: string | null | undefined;
            }
          | null
          | undefined;
        errors: Array<
          | {
              __typename: 'DecryptionFailedError';
              code: ConnectionSessionErrorCode;
              reason: string;
            }
          | {
              __typename: 'InvalidAttributesError';
              code: ConnectionSessionErrorCode;
              reason: string;
              received_attributes: Array<{
                __typename?: 'ReceivedAttribute';
                attribute: string;
                value?: string | null | undefined;
              }>;
              expected_attributes: {
                __typename?: 'ExpectedAttributes';
                email_attribute: string;
                first_name_attribute: string;
                idp_id_attribute: string;
                last_name_attribute: string;
              };
            }
          | {
              __typename: 'InvalidX509CertError';
              code: ConnectionSessionErrorCode;
              reason: string;
            }
          | {
              __typename: 'MalformedSamlResponseError';
              code: ConnectionSessionErrorCode;
              reason: string;
            }
          | {
              __typename: 'ProfileNotAllowedOutsideOrganizationError';
              code: ConnectionSessionErrorCode;
              reason: string;
              profileDomain: string;
              organizationDomains: Array<string>;
            }
        >;
      };
};

export type ConnectionSessionsQueryVariables = Exact<{
  samlSessionState?: InputMaybe<SamlSessionState>;
  oauthSessionState?: InputMaybe<OAuthSessionState>;
  oidcSessionState?: InputMaybe<OidcSessionState>;
}>;

export type ConnectionSessionsQuery = {
  __typename?: 'Query';
  portal_connections: {
    __typename?: 'portal_ConnectionList';
    data: Array<{
      __typename?: 'portal_Connection';
      samlSessions: {
        __typename?: 'SAMLSessionsList';
        data: Array<{
          __typename: 'SAMLSession';
          createdAt: any;
          id: string;
          saml_request?: string | null | undefined;
          saml_response?: string | null | undefined;
          samlSessionState: SamlSessionState;
          profile?:
            | {
                __typename?: 'Profile';
                idp_id?: string | null | undefined;
                email?: string | null | undefined;
                first_name?: string | null | undefined;
                last_name?: string | null | undefined;
              }
            | null
            | undefined;
          errors: Array<
            | {
                __typename: 'DecryptionFailedError';
                code: ConnectionSessionErrorCode;
                reason: string;
              }
            | {
                __typename: 'InvalidAttributesError';
                code: ConnectionSessionErrorCode;
                reason: string;
                received_attributes: Array<{
                  __typename?: 'ReceivedAttribute';
                  attribute: string;
                  value?: string | null | undefined;
                }>;
                expected_attributes: {
                  __typename?: 'ExpectedAttributes';
                  email_attribute: string;
                  first_name_attribute: string;
                  idp_id_attribute: string;
                  last_name_attribute: string;
                };
              }
            | {
                __typename: 'InvalidX509CertError';
                code: ConnectionSessionErrorCode;
                reason: string;
              }
            | {
                __typename: 'MalformedSamlResponseError';
                code: ConnectionSessionErrorCode;
                reason: string;
              }
            | {
                __typename: 'ProfileNotAllowedOutsideOrganizationError';
                code: ConnectionSessionErrorCode;
                reason: string;
                profileDomain: string;
                organizationDomains: Array<string>;
              }
          >;
        }>;
      };
      oauthSessions: {
        __typename?: 'OAuthSessionsList';
        data: Array<{
          __typename: 'OAuthSession';
          createdAt: any;
          id: string;
          oauthSessionState: OAuthSessionState;
          profile?:
            | {
                __typename?: 'Profile';
                idp_id?: string | null | undefined;
                email?: string | null | undefined;
                first_name?: string | null | undefined;
                last_name?: string | null | undefined;
                rawAttributes?: any | null | undefined;
              }
            | null
            | undefined;
          errors: Array<
            | {
                __typename: 'DecryptionFailedError';
                code: ConnectionSessionErrorCode;
                reason: string;
              }
            | {
                __typename: 'InvalidAttributesError';
                code: ConnectionSessionErrorCode;
                reason: string;
                received_attributes: Array<{
                  __typename?: 'ReceivedAttribute';
                  attribute: string;
                  value?: string | null | undefined;
                }>;
                expected_attributes: {
                  __typename?: 'ExpectedAttributes';
                  email_attribute: string;
                  first_name_attribute: string;
                  idp_id_attribute: string;
                  last_name_attribute: string;
                };
              }
            | {
                __typename: 'InvalidX509CertError';
                code: ConnectionSessionErrorCode;
                reason: string;
              }
            | {
                __typename: 'MalformedSamlResponseError';
                code: ConnectionSessionErrorCode;
                reason: string;
              }
            | {
                __typename: 'ProfileNotAllowedOutsideOrganizationError';
                code: ConnectionSessionErrorCode;
                reason: string;
                profileDomain: string;
                organizationDomains: Array<string>;
              }
          >;
        }>;
      };
      oidcSessions: {
        __typename?: 'OidcSessionsList';
        data: Array<{
          __typename: 'OidcSession';
          createdAt: any;
          id: string;
          oidcSessionState: OidcSessionState;
          profile?:
            | {
                __typename?: 'Profile';
                idp_id?: string | null | undefined;
                email?: string | null | undefined;
                first_name?: string | null | undefined;
                last_name?: string | null | undefined;
                rawAttributes?: any | null | undefined;
              }
            | null
            | undefined;
          errors: Array<
            | {
                __typename: 'DecryptionFailedError';
                code: ConnectionSessionErrorCode;
                reason: string;
              }
            | {
                __typename: 'InvalidAttributesError';
                code: ConnectionSessionErrorCode;
                reason: string;
                received_attributes: Array<{
                  __typename?: 'ReceivedAttribute';
                  attribute: string;
                  value?: string | null | undefined;
                }>;
                expected_attributes: {
                  __typename?: 'ExpectedAttributes';
                  email_attribute: string;
                  first_name_attribute: string;
                  idp_id_attribute: string;
                  last_name_attribute: string;
                };
              }
            | {
                __typename: 'InvalidX509CertError';
                code: ConnectionSessionErrorCode;
                reason: string;
              }
            | {
                __typename: 'MalformedSamlResponseError';
                code: ConnectionSessionErrorCode;
                reason: string;
              }
            | {
                __typename: 'ProfileNotAllowedOutsideOrganizationError';
                code: ConnectionSessionErrorCode;
                reason: string;
                profileDomain: string;
                organizationDomains: Array<string>;
              }
          >;
        }>;
      };
    }>;
  };
};

export type ConnectionFragment = {
  __typename?: 'portal_Connection';
  id: string;
  type: ConnectionType;
  createdAt: any;
  name: string;
  oidc_client_id?: string | null | undefined;
  oidc_client_secret?: string | null | undefined;
  oidc_discovery_endpoint?: string | null | undefined;
  oidc_redirect_uri?: string | null | undefined;
  status: ConnectionStatus;
  state: ConnectionState;
  saml_acs_url: string;
  saml_entity_id?: string | null | undefined;
  saml_idp_url?: string | null | undefined;
  saml_x509_certs?: Array<string> | null | undefined;
  saml_idp_metadata_url?: string | null | undefined;
  saml_sp_metadata_url: string;
  adpConnectionSslCertificates: Array<{
    __typename?: 'Portal_AdpConnectionSslCertificate';
    id: string;
    value: string;
  }>;
  attribute_map?:
    | { __typename?: 'AttributeMap'; id: string }
    | null
    | undefined;
  connectionDomains: Array<{
    __typename?: 'ConnectionDomain';
    id: string;
    domain: string;
  }>;
  samlX509Certificates: Array<{
    __typename?: 'portal_SamlX509Certificate';
    id: string;
    value: string;
    notBefore?: any | null | undefined;
    notAfter?: any | null | undefined;
  }>;
  saml: {
    __typename?: 'Portal_SamlConnection';
    idpEntityId?: string | null | undefined;
  };
};

export type ConnectionsQueryVariables = Exact<{ [key: string]: never }>;

export type ConnectionsQuery = {
  __typename?: 'Query';
  portal_connections: {
    __typename?: 'portal_ConnectionList';
    data: Array<{
      __typename?: 'portal_Connection';
      id: string;
      type: ConnectionType;
      createdAt: any;
      name: string;
      oidc_client_id?: string | null | undefined;
      oidc_client_secret?: string | null | undefined;
      oidc_discovery_endpoint?: string | null | undefined;
      oidc_redirect_uri?: string | null | undefined;
      status: ConnectionStatus;
      state: ConnectionState;
      saml_acs_url: string;
      saml_entity_id?: string | null | undefined;
      saml_idp_url?: string | null | undefined;
      saml_x509_certs?: Array<string> | null | undefined;
      saml_idp_metadata_url?: string | null | undefined;
      saml_sp_metadata_url: string;
      adpConnectionSslCertificates: Array<{
        __typename?: 'Portal_AdpConnectionSslCertificate';
        id: string;
        value: string;
      }>;
      attribute_map?:
        | { __typename?: 'AttributeMap'; id: string }
        | null
        | undefined;
      connectionDomains: Array<{
        __typename?: 'ConnectionDomain';
        id: string;
        domain: string;
      }>;
      samlX509Certificates: Array<{
        __typename?: 'portal_SamlX509Certificate';
        id: string;
        value: string;
        notBefore?: any | null | undefined;
        notAfter?: any | null | undefined;
      }>;
      saml: {
        __typename?: 'Portal_SamlConnection';
        idpEntityId?: string | null | undefined;
      };
    }>;
  };
};

export type CreateConnectionMutationVariables = Exact<{
  name: Scalars['String'];
  type: ConnectionType;
  domains?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type CreateConnectionMutation = {
  __typename?: 'Mutation';
  portal_createConnection: {
    __typename?: 'portal_Connection';
    id: string;
    type: ConnectionType;
    createdAt: any;
    name: string;
    oidc_client_id?: string | null | undefined;
    oidc_client_secret?: string | null | undefined;
    oidc_discovery_endpoint?: string | null | undefined;
    oidc_redirect_uri?: string | null | undefined;
    status: ConnectionStatus;
    state: ConnectionState;
    saml_acs_url: string;
    saml_entity_id?: string | null | undefined;
    saml_idp_url?: string | null | undefined;
    saml_x509_certs?: Array<string> | null | undefined;
    saml_idp_metadata_url?: string | null | undefined;
    saml_sp_metadata_url: string;
    adpConnectionSslCertificates: Array<{
      __typename?: 'Portal_AdpConnectionSslCertificate';
      id: string;
      value: string;
    }>;
    attribute_map?:
      | { __typename?: 'AttributeMap'; id: string }
      | null
      | undefined;
    connectionDomains: Array<{
      __typename?: 'ConnectionDomain';
      id: string;
      domain: string;
    }>;
    samlX509Certificates: Array<{
      __typename?: 'portal_SamlX509Certificate';
      id: string;
      value: string;
      notBefore?: any | null | undefined;
      notAfter?: any | null | undefined;
    }>;
    saml: {
      __typename?: 'Portal_SamlConnection';
      idpEntityId?: string | null | undefined;
    };
  };
};

export type CreateDirectoryMutationVariables = Exact<{
  name: Scalars['String'];
  type: DirectoryType;
  domain?: InputMaybe<Scalars['String']>;
}>;

export type CreateDirectoryMutation = {
  __typename?: 'Mutation';
  portal_createDirectory: {
    __typename?: 'portal_Directory';
    id: string;
    name: string;
    type: DirectoryType;
    state: DirectoryState;
    endpoint?: string | null | undefined;
    setup_url?: string | null | undefined;
    createdAt: any;
    bearer_token?: string | null | undefined;
    bamboo_hr_api_key?: string | null | undefined;
    breatheHrApiKey?: string | null | undefined;
    bamboo_hr_subdomain?: string | null | undefined;
    bob_service_user_id?: string | null | undefined;
    bob_api_token?: string | null | undefined;
    rippling_api_key?: string | null | undefined;
    workday_raas_endpoint?: string | null | undefined;
    workday_raas_group_endpoint?: string | null | undefined;
    workday_raas_password?: string | null | undefined;
    workday_raas_username?: string | null | undefined;
    fourthHrOrganizationId?: string | null | undefined;
    fourthHrPassword?: string | null | undefined;
    fourthHrUsername?: string | null | undefined;
    attributeMap?:
      | {
          __typename?: 'DirectoryAttributeMap';
          id: string;
          external_id_attribute?: string | null | undefined;
          emails_attribute?: string | null | undefined;
          username_attribute?: string | null | undefined;
          first_name_attribute?: string | null | undefined;
          last_name_attribute?: string | null | undefined;
          group_name_attribute?: string | null | undefined;
        }
      | null
      | undefined;
    customAttributeMappings: Array<{
      __typename?: 'Portal_DirectoryCustomAttributeMapping';
      id: string;
      attribute: string;
      customAttributeId: string;
    }>;
    countsSummary: {
      __typename?: 'Portal_DirectoryCountsSummary';
      userCount: number;
      groupCount: number;
    };
  };
};

export type DeleteDirectoryMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteDirectoryMutation = {
  __typename?: 'Mutation';
  portal_deleteDirectory: {
    __typename?: 'Portal_DeleteDirectoryPayload';
    directory?:
      | { __typename?: 'portal_Directory'; id: string }
      | null
      | undefined;
  };
};

export type DeleteDraftConnectionMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteDraftConnectionMutation = {
  __typename?: 'Mutation';
  portal_deleteDraftConnection: string;
};

export type DeleteSamlX509CertificateMutationVariables = Exact<{
  input: Portal_DeleteSamlX509CertificateInput;
}>;

export type DeleteSamlX509CertificateMutation = {
  __typename?: 'Mutation';
  portal_deleteSamlX509Certificate:
    | {
        __typename: 'Portal_SamlX509CertificateDeleted';
        samlX509Certificate: {
          __typename?: 'portal_SamlX509Certificate';
          id: string;
        };
      }
    | {
        __typename: 'SamlX509CertificateNotFound';
        samlX509CertificateId: string;
      };
};

export type DirectoryCustomAttributeMappingFragment = {
  __typename?: 'Portal_DirectoryCustomAttributeMapping';
  id: string;
  attribute: string;
  customAttributeId: string;
};

export type DirectoryCustomAttributeFragment = {
  __typename?: 'Portal_DirectoryCustomAttribute';
  id: string;
  isRequired: boolean;
  name: string;
};

export type DirectoryCustomAttributesQueryVariables = Exact<{
  directoryId: Scalars['String'];
}>;

export type DirectoryCustomAttributesQuery = {
  __typename?: 'Query';
  portal_directoryCustomAttributes: {
    __typename?: 'Portal_DirectoryCustomAttributeList';
    data: Array<{
      __typename?: 'Portal_DirectoryCustomAttribute';
      id: string;
      isRequired: boolean;
      name: string;
    }>;
  };
};

export type DirectoryGroupFragment = {
  __typename?: 'DirectoryGroup';
  id: string;
  name: string;
};

export type DirectoryOverviewQueryVariables = Exact<{
  id: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<DirectorySyncRunState>;
}>;

export type DirectoryOverviewQuery = {
  __typename?: 'Query';
  portal_directory: {
    __typename?: 'portal_Directory';
    id: string;
    name: string;
    type: DirectoryType;
    state: DirectoryState;
    endpoint?: string | null | undefined;
    setup_url?: string | null | undefined;
    createdAt: any;
    bearer_token?: string | null | undefined;
    bamboo_hr_api_key?: string | null | undefined;
    breatheHrApiKey?: string | null | undefined;
    bamboo_hr_subdomain?: string | null | undefined;
    bob_service_user_id?: string | null | undefined;
    bob_api_token?: string | null | undefined;
    rippling_api_key?: string | null | undefined;
    workday_raas_endpoint?: string | null | undefined;
    workday_raas_group_endpoint?: string | null | undefined;
    workday_raas_password?: string | null | undefined;
    workday_raas_username?: string | null | undefined;
    fourthHrOrganizationId?: string | null | undefined;
    fourthHrPassword?: string | null | undefined;
    fourthHrUsername?: string | null | undefined;
    directorySyncRuns: {
      __typename?: 'portal_DirectorySyncRunsList';
      data: Array<{
        __typename?: 'Portal_DirectorySyncRun';
        id: string;
        state: DirectorySyncRunState;
        updatedAt: any;
        errors: Array<
          | {
              __typename: 'MalformedDirectoryGroupError';
              code: DirectorySyncRunErrorCode;
              reason: string;
            }
          | {
              __typename: 'MalformedDirectoryUserError';
              code: DirectorySyncRunErrorCode;
              reason: string;
            }
        >;
      }>;
    };
    attributeMap?:
      | {
          __typename?: 'DirectoryAttributeMap';
          id: string;
          external_id_attribute?: string | null | undefined;
          emails_attribute?: string | null | undefined;
          username_attribute?: string | null | undefined;
          first_name_attribute?: string | null | undefined;
          last_name_attribute?: string | null | undefined;
          group_name_attribute?: string | null | undefined;
        }
      | null
      | undefined;
    customAttributeMappings: Array<{
      __typename?: 'Portal_DirectoryCustomAttributeMapping';
      id: string;
      attribute: string;
      customAttributeId: string;
    }>;
    countsSummary: {
      __typename?: 'Portal_DirectoryCountsSummary';
      userCount: number;
      groupCount: number;
    };
  };
};

export type DirectorySyncRunFragment = {
  __typename?: 'Portal_DirectorySyncRun';
  id: string;
  state: DirectorySyncRunState;
  updatedAt: any;
  errors: Array<
    | {
        __typename: 'MalformedDirectoryGroupError';
        code: DirectorySyncRunErrorCode;
        reason: string;
      }
    | {
        __typename: 'MalformedDirectoryUserError';
        code: DirectorySyncRunErrorCode;
        reason: string;
      }
  >;
};

export type DirectoryUserFragment = {
  __typename?: 'DirectoryUser';
  id: string;
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
  username?: string | null | undefined;
  state: DirectoryUserState;
  directoryGroups: Array<{
    __typename?: 'DirectoryGroup';
    id: string;
    name: string;
  }>;
};

export type DirectoryUsersQueryVariables = Exact<{
  directoryId: Scalars['String'];
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type DirectoryUsersQuery = {
  __typename?: 'Query';
  portal_directory: {
    __typename?: 'portal_Directory';
    directoryUsers: {
      __typename?: 'DirectoryUsersList';
      data: Array<{
        __typename?: 'DirectoryUser';
        id: string;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        username?: string | null | undefined;
        state: DirectoryUserState;
        directoryGroups: Array<{
          __typename?: 'DirectoryGroup';
          id: string;
          name: string;
        }>;
      }>;
      listMetadata: {
        __typename?: 'ListMetadata';
        before?: string | null | undefined;
        after?: string | null | undefined;
      };
    };
  };
};

export type DirectoryFragment = {
  __typename?: 'portal_Directory';
  id: string;
  name: string;
  type: DirectoryType;
  state: DirectoryState;
  endpoint?: string | null | undefined;
  setup_url?: string | null | undefined;
  createdAt: any;
  bearer_token?: string | null | undefined;
  bamboo_hr_api_key?: string | null | undefined;
  breatheHrApiKey?: string | null | undefined;
  bamboo_hr_subdomain?: string | null | undefined;
  bob_service_user_id?: string | null | undefined;
  bob_api_token?: string | null | undefined;
  rippling_api_key?: string | null | undefined;
  workday_raas_endpoint?: string | null | undefined;
  workday_raas_group_endpoint?: string | null | undefined;
  workday_raas_password?: string | null | undefined;
  workday_raas_username?: string | null | undefined;
  fourthHrOrganizationId?: string | null | undefined;
  fourthHrPassword?: string | null | undefined;
  fourthHrUsername?: string | null | undefined;
  attributeMap?:
    | {
        __typename?: 'DirectoryAttributeMap';
        id: string;
        external_id_attribute?: string | null | undefined;
        emails_attribute?: string | null | undefined;
        username_attribute?: string | null | undefined;
        first_name_attribute?: string | null | undefined;
        last_name_attribute?: string | null | undefined;
        group_name_attribute?: string | null | undefined;
      }
    | null
    | undefined;
  customAttributeMappings: Array<{
    __typename?: 'Portal_DirectoryCustomAttributeMapping';
    id: string;
    attribute: string;
    customAttributeId: string;
  }>;
  countsSummary: {
    __typename?: 'Portal_DirectoryCountsSummary';
    userCount: number;
    groupCount: number;
  };
};

export type DirectoryQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type DirectoryQuery = {
  __typename?: 'Query';
  portal_directory: {
    __typename?: 'portal_Directory';
    id: string;
    name: string;
    type: DirectoryType;
    state: DirectoryState;
    endpoint?: string | null | undefined;
    setup_url?: string | null | undefined;
    createdAt: any;
    bearer_token?: string | null | undefined;
    bamboo_hr_api_key?: string | null | undefined;
    breatheHrApiKey?: string | null | undefined;
    bamboo_hr_subdomain?: string | null | undefined;
    bob_service_user_id?: string | null | undefined;
    bob_api_token?: string | null | undefined;
    rippling_api_key?: string | null | undefined;
    workday_raas_endpoint?: string | null | undefined;
    workday_raas_group_endpoint?: string | null | undefined;
    workday_raas_password?: string | null | undefined;
    workday_raas_username?: string | null | undefined;
    fourthHrOrganizationId?: string | null | undefined;
    fourthHrPassword?: string | null | undefined;
    fourthHrUsername?: string | null | undefined;
    attributeMap?:
      | {
          __typename?: 'DirectoryAttributeMap';
          id: string;
          external_id_attribute?: string | null | undefined;
          emails_attribute?: string | null | undefined;
          username_attribute?: string | null | undefined;
          first_name_attribute?: string | null | undefined;
          last_name_attribute?: string | null | undefined;
          group_name_attribute?: string | null | undefined;
        }
      | null
      | undefined;
    customAttributeMappings: Array<{
      __typename?: 'Portal_DirectoryCustomAttributeMapping';
      id: string;
      attribute: string;
      customAttributeId: string;
    }>;
    countsSummary: {
      __typename?: 'Portal_DirectoryCountsSummary';
      userCount: number;
      groupCount: number;
    };
  };
};

export type InitialDsyncQueryQueryVariables = Exact<{ [key: string]: never }>;

export type InitialDsyncQueryQuery = {
  __typename?: 'Query';
  portal_portalSession: {
    __typename?: 'portal_PortalSession';
    id: string;
    returnUrl: string;
    appName: string;
    connection?:
      | {
          __typename?: 'portal_Connection';
          id: string;
          type: ConnectionType;
          createdAt: any;
          name: string;
          oidc_client_id?: string | null | undefined;
          oidc_client_secret?: string | null | undefined;
          oidc_discovery_endpoint?: string | null | undefined;
          oidc_redirect_uri?: string | null | undefined;
          status: ConnectionStatus;
          state: ConnectionState;
          saml_acs_url: string;
          saml_entity_id?: string | null | undefined;
          saml_idp_url?: string | null | undefined;
          saml_x509_certs?: Array<string> | null | undefined;
          saml_idp_metadata_url?: string | null | undefined;
          saml_sp_metadata_url: string;
          adpConnectionSslCertificates: Array<{
            __typename?: 'Portal_AdpConnectionSslCertificate';
            id: string;
            value: string;
          }>;
          attribute_map?:
            | { __typename?: 'AttributeMap'; id: string }
            | null
            | undefined;
          connectionDomains: Array<{
            __typename?: 'ConnectionDomain';
            id: string;
            domain: string;
          }>;
          samlX509Certificates: Array<{
            __typename?: 'portal_SamlX509Certificate';
            id: string;
            value: string;
            notBefore?: any | null | undefined;
            notAfter?: any | null | undefined;
          }>;
          saml: {
            __typename?: 'Portal_SamlConnection';
            idpEntityId?: string | null | undefined;
          };
        }
      | null
      | undefined;
    directory?:
      | {
          __typename?: 'portal_Directory';
          id: string;
          name: string;
          type: DirectoryType;
          state: DirectoryState;
          endpoint?: string | null | undefined;
          setup_url?: string | null | undefined;
          createdAt: any;
          bearer_token?: string | null | undefined;
          bamboo_hr_api_key?: string | null | undefined;
          breatheHrApiKey?: string | null | undefined;
          bamboo_hr_subdomain?: string | null | undefined;
          bob_service_user_id?: string | null | undefined;
          bob_api_token?: string | null | undefined;
          rippling_api_key?: string | null | undefined;
          workday_raas_endpoint?: string | null | undefined;
          workday_raas_group_endpoint?: string | null | undefined;
          workday_raas_password?: string | null | undefined;
          workday_raas_username?: string | null | undefined;
          fourthHrOrganizationId?: string | null | undefined;
          fourthHrPassword?: string | null | undefined;
          fourthHrUsername?: string | null | undefined;
          attributeMap?:
            | {
                __typename?: 'DirectoryAttributeMap';
                id: string;
                external_id_attribute?: string | null | undefined;
                emails_attribute?: string | null | undefined;
                username_attribute?: string | null | undefined;
                first_name_attribute?: string | null | undefined;
                last_name_attribute?: string | null | undefined;
                group_name_attribute?: string | null | undefined;
              }
            | null
            | undefined;
          customAttributeMappings: Array<{
            __typename?: 'Portal_DirectoryCustomAttributeMapping';
            id: string;
            attribute: string;
            customAttributeId: string;
          }>;
          countsSummary: {
            __typename?: 'Portal_DirectoryCountsSummary';
            userCount: number;
            groupCount: number;
          };
        }
      | null
      | undefined;
    organization: {
      __typename?: 'Organization';
      id: string;
      name: string;
      domains: Array<{
        __typename?: 'OrganizationDomain';
        id: string;
        domain: string;
      }>;
    };
    portalSettings: {
      __typename?: 'PortalSettings';
      logoUrl?: string | null | undefined;
      primaryColor?: string | null | undefined;
      faviconUrl?: string | null | undefined;
    };
  };
  portal_directories: {
    __typename?: 'portal_DirectoryList';
    data: Array<{
      __typename?: 'portal_Directory';
      id: string;
      name: string;
      type: DirectoryType;
      state: DirectoryState;
      endpoint?: string | null | undefined;
      setup_url?: string | null | undefined;
      createdAt: any;
      bearer_token?: string | null | undefined;
      bamboo_hr_api_key?: string | null | undefined;
      breatheHrApiKey?: string | null | undefined;
      bamboo_hr_subdomain?: string | null | undefined;
      bob_service_user_id?: string | null | undefined;
      bob_api_token?: string | null | undefined;
      rippling_api_key?: string | null | undefined;
      workday_raas_endpoint?: string | null | undefined;
      workday_raas_group_endpoint?: string | null | undefined;
      workday_raas_password?: string | null | undefined;
      workday_raas_username?: string | null | undefined;
      fourthHrOrganizationId?: string | null | undefined;
      fourthHrPassword?: string | null | undefined;
      fourthHrUsername?: string | null | undefined;
      attributeMap?:
        | {
            __typename?: 'DirectoryAttributeMap';
            id: string;
            external_id_attribute?: string | null | undefined;
            emails_attribute?: string | null | undefined;
            username_attribute?: string | null | undefined;
            first_name_attribute?: string | null | undefined;
            last_name_attribute?: string | null | undefined;
            group_name_attribute?: string | null | undefined;
          }
        | null
        | undefined;
      customAttributeMappings: Array<{
        __typename?: 'Portal_DirectoryCustomAttributeMapping';
        id: string;
        attribute: string;
        customAttributeId: string;
      }>;
      countsSummary: {
        __typename?: 'Portal_DirectoryCountsSummary';
        userCount: number;
        groupCount: number;
      };
    }>;
  };
};

export type InitialPortalQueryVariables = Exact<{ [key: string]: never }>;

export type InitialPortalQuery = {
  __typename?: 'Query';
  portal_portalSession: {
    __typename?: 'portal_PortalSession';
    id: string;
    returnUrl: string;
    appName: string;
    connection?:
      | {
          __typename?: 'portal_Connection';
          id: string;
          type: ConnectionType;
          createdAt: any;
          name: string;
          oidc_client_id?: string | null | undefined;
          oidc_client_secret?: string | null | undefined;
          oidc_discovery_endpoint?: string | null | undefined;
          oidc_redirect_uri?: string | null | undefined;
          status: ConnectionStatus;
          state: ConnectionState;
          saml_acs_url: string;
          saml_entity_id?: string | null | undefined;
          saml_idp_url?: string | null | undefined;
          saml_x509_certs?: Array<string> | null | undefined;
          saml_idp_metadata_url?: string | null | undefined;
          saml_sp_metadata_url: string;
          adpConnectionSslCertificates: Array<{
            __typename?: 'Portal_AdpConnectionSslCertificate';
            id: string;
            value: string;
          }>;
          attribute_map?:
            | { __typename?: 'AttributeMap'; id: string }
            | null
            | undefined;
          connectionDomains: Array<{
            __typename?: 'ConnectionDomain';
            id: string;
            domain: string;
          }>;
          samlX509Certificates: Array<{
            __typename?: 'portal_SamlX509Certificate';
            id: string;
            value: string;
            notBefore?: any | null | undefined;
            notAfter?: any | null | undefined;
          }>;
          saml: {
            __typename?: 'Portal_SamlConnection';
            idpEntityId?: string | null | undefined;
          };
        }
      | null
      | undefined;
    directory?:
      | {
          __typename?: 'portal_Directory';
          id: string;
          name: string;
          type: DirectoryType;
          state: DirectoryState;
          endpoint?: string | null | undefined;
          setup_url?: string | null | undefined;
          createdAt: any;
          bearer_token?: string | null | undefined;
          bamboo_hr_api_key?: string | null | undefined;
          breatheHrApiKey?: string | null | undefined;
          bamboo_hr_subdomain?: string | null | undefined;
          bob_service_user_id?: string | null | undefined;
          bob_api_token?: string | null | undefined;
          rippling_api_key?: string | null | undefined;
          workday_raas_endpoint?: string | null | undefined;
          workday_raas_group_endpoint?: string | null | undefined;
          workday_raas_password?: string | null | undefined;
          workday_raas_username?: string | null | undefined;
          fourthHrOrganizationId?: string | null | undefined;
          fourthHrPassword?: string | null | undefined;
          fourthHrUsername?: string | null | undefined;
          attributeMap?:
            | {
                __typename?: 'DirectoryAttributeMap';
                id: string;
                external_id_attribute?: string | null | undefined;
                emails_attribute?: string | null | undefined;
                username_attribute?: string | null | undefined;
                first_name_attribute?: string | null | undefined;
                last_name_attribute?: string | null | undefined;
                group_name_attribute?: string | null | undefined;
              }
            | null
            | undefined;
          customAttributeMappings: Array<{
            __typename?: 'Portal_DirectoryCustomAttributeMapping';
            id: string;
            attribute: string;
            customAttributeId: string;
          }>;
          countsSummary: {
            __typename?: 'Portal_DirectoryCountsSummary';
            userCount: number;
            groupCount: number;
          };
        }
      | null
      | undefined;
    organization: {
      __typename?: 'Organization';
      id: string;
      name: string;
      domains: Array<{
        __typename?: 'OrganizationDomain';
        id: string;
        domain: string;
      }>;
    };
    portalSettings: {
      __typename?: 'PortalSettings';
      logoUrl?: string | null | undefined;
      primaryColor?: string | null | undefined;
      faviconUrl?: string | null | undefined;
    };
  };
};

export type InitialSsoQueryQueryVariables = Exact<{ [key: string]: never }>;

export type InitialSsoQueryQuery = {
  __typename?: 'Query';
  portal_portalSession: {
    __typename?: 'portal_PortalSession';
    id: string;
    returnUrl: string;
    appName: string;
    connection?:
      | {
          __typename?: 'portal_Connection';
          id: string;
          type: ConnectionType;
          createdAt: any;
          name: string;
          oidc_client_id?: string | null | undefined;
          oidc_client_secret?: string | null | undefined;
          oidc_discovery_endpoint?: string | null | undefined;
          oidc_redirect_uri?: string | null | undefined;
          status: ConnectionStatus;
          state: ConnectionState;
          saml_acs_url: string;
          saml_entity_id?: string | null | undefined;
          saml_idp_url?: string | null | undefined;
          saml_x509_certs?: Array<string> | null | undefined;
          saml_idp_metadata_url?: string | null | undefined;
          saml_sp_metadata_url: string;
          adpConnectionSslCertificates: Array<{
            __typename?: 'Portal_AdpConnectionSslCertificate';
            id: string;
            value: string;
          }>;
          attribute_map?:
            | { __typename?: 'AttributeMap'; id: string }
            | null
            | undefined;
          connectionDomains: Array<{
            __typename?: 'ConnectionDomain';
            id: string;
            domain: string;
          }>;
          samlX509Certificates: Array<{
            __typename?: 'portal_SamlX509Certificate';
            id: string;
            value: string;
            notBefore?: any | null | undefined;
            notAfter?: any | null | undefined;
          }>;
          saml: {
            __typename?: 'Portal_SamlConnection';
            idpEntityId?: string | null | undefined;
          };
        }
      | null
      | undefined;
    directory?:
      | {
          __typename?: 'portal_Directory';
          id: string;
          name: string;
          type: DirectoryType;
          state: DirectoryState;
          endpoint?: string | null | undefined;
          setup_url?: string | null | undefined;
          createdAt: any;
          bearer_token?: string | null | undefined;
          bamboo_hr_api_key?: string | null | undefined;
          breatheHrApiKey?: string | null | undefined;
          bamboo_hr_subdomain?: string | null | undefined;
          bob_service_user_id?: string | null | undefined;
          bob_api_token?: string | null | undefined;
          rippling_api_key?: string | null | undefined;
          workday_raas_endpoint?: string | null | undefined;
          workday_raas_group_endpoint?: string | null | undefined;
          workday_raas_password?: string | null | undefined;
          workday_raas_username?: string | null | undefined;
          fourthHrOrganizationId?: string | null | undefined;
          fourthHrPassword?: string | null | undefined;
          fourthHrUsername?: string | null | undefined;
          attributeMap?:
            | {
                __typename?: 'DirectoryAttributeMap';
                id: string;
                external_id_attribute?: string | null | undefined;
                emails_attribute?: string | null | undefined;
                username_attribute?: string | null | undefined;
                first_name_attribute?: string | null | undefined;
                last_name_attribute?: string | null | undefined;
                group_name_attribute?: string | null | undefined;
              }
            | null
            | undefined;
          customAttributeMappings: Array<{
            __typename?: 'Portal_DirectoryCustomAttributeMapping';
            id: string;
            attribute: string;
            customAttributeId: string;
          }>;
          countsSummary: {
            __typename?: 'Portal_DirectoryCountsSummary';
            userCount: number;
            groupCount: number;
          };
        }
      | null
      | undefined;
    organization: {
      __typename?: 'Organization';
      id: string;
      name: string;
      domains: Array<{
        __typename?: 'OrganizationDomain';
        id: string;
        domain: string;
      }>;
    };
    portalSettings: {
      __typename?: 'PortalSettings';
      logoUrl?: string | null | undefined;
      primaryColor?: string | null | undefined;
      faviconUrl?: string | null | undefined;
    };
  };
  portal_connections: {
    __typename?: 'portal_ConnectionList';
    data: Array<{
      __typename?: 'portal_Connection';
      id: string;
      type: ConnectionType;
      createdAt: any;
      name: string;
      oidc_client_id?: string | null | undefined;
      oidc_client_secret?: string | null | undefined;
      oidc_discovery_endpoint?: string | null | undefined;
      oidc_redirect_uri?: string | null | undefined;
      status: ConnectionStatus;
      state: ConnectionState;
      saml_acs_url: string;
      saml_entity_id?: string | null | undefined;
      saml_idp_url?: string | null | undefined;
      saml_x509_certs?: Array<string> | null | undefined;
      saml_idp_metadata_url?: string | null | undefined;
      saml_sp_metadata_url: string;
      adpConnectionSslCertificates: Array<{
        __typename?: 'Portal_AdpConnectionSslCertificate';
        id: string;
        value: string;
      }>;
      attribute_map?:
        | { __typename?: 'AttributeMap'; id: string }
        | null
        | undefined;
      connectionDomains: Array<{
        __typename?: 'ConnectionDomain';
        id: string;
        domain: string;
      }>;
      samlX509Certificates: Array<{
        __typename?: 'portal_SamlX509Certificate';
        id: string;
        value: string;
        notBefore?: any | null | undefined;
        notAfter?: any | null | undefined;
      }>;
      saml: {
        __typename?: 'Portal_SamlConnection';
        idpEntityId?: string | null | undefined;
      };
    }>;
  };
};

export type LoadedMutationVariables = Exact<{
  started_at: Scalars['Float'];
  legacy?: InputMaybe<Scalars['Boolean']>;
}>;

export type LoadedMutation = {
  __typename?: 'Mutation';
  portal_loaded: { __typename?: 'SuccessResponse'; success: boolean };
};

export type OrganizationFragment = {
  __typename?: 'Organization';
  id: string;
  name: string;
  domains: Array<{
    __typename?: 'OrganizationDomain';
    id: string;
    domain: string;
  }>;
};

export type PortalSessionFragment = {
  __typename?: 'portal_PortalSession';
  id: string;
  returnUrl: string;
  appName: string;
  connection?:
    | {
        __typename?: 'portal_Connection';
        id: string;
        type: ConnectionType;
        createdAt: any;
        name: string;
        oidc_client_id?: string | null | undefined;
        oidc_client_secret?: string | null | undefined;
        oidc_discovery_endpoint?: string | null | undefined;
        oidc_redirect_uri?: string | null | undefined;
        status: ConnectionStatus;
        state: ConnectionState;
        saml_acs_url: string;
        saml_entity_id?: string | null | undefined;
        saml_idp_url?: string | null | undefined;
        saml_x509_certs?: Array<string> | null | undefined;
        saml_idp_metadata_url?: string | null | undefined;
        saml_sp_metadata_url: string;
        adpConnectionSslCertificates: Array<{
          __typename?: 'Portal_AdpConnectionSslCertificate';
          id: string;
          value: string;
        }>;
        attribute_map?:
          | { __typename?: 'AttributeMap'; id: string }
          | null
          | undefined;
        connectionDomains: Array<{
          __typename?: 'ConnectionDomain';
          id: string;
          domain: string;
        }>;
        samlX509Certificates: Array<{
          __typename?: 'portal_SamlX509Certificate';
          id: string;
          value: string;
          notBefore?: any | null | undefined;
          notAfter?: any | null | undefined;
        }>;
        saml: {
          __typename?: 'Portal_SamlConnection';
          idpEntityId?: string | null | undefined;
        };
      }
    | null
    | undefined;
  directory?:
    | {
        __typename?: 'portal_Directory';
        id: string;
        name: string;
        type: DirectoryType;
        state: DirectoryState;
        endpoint?: string | null | undefined;
        setup_url?: string | null | undefined;
        createdAt: any;
        bearer_token?: string | null | undefined;
        bamboo_hr_api_key?: string | null | undefined;
        breatheHrApiKey?: string | null | undefined;
        bamboo_hr_subdomain?: string | null | undefined;
        bob_service_user_id?: string | null | undefined;
        bob_api_token?: string | null | undefined;
        rippling_api_key?: string | null | undefined;
        workday_raas_endpoint?: string | null | undefined;
        workday_raas_group_endpoint?: string | null | undefined;
        workday_raas_password?: string | null | undefined;
        workday_raas_username?: string | null | undefined;
        fourthHrOrganizationId?: string | null | undefined;
        fourthHrPassword?: string | null | undefined;
        fourthHrUsername?: string | null | undefined;
        attributeMap?:
          | {
              __typename?: 'DirectoryAttributeMap';
              id: string;
              external_id_attribute?: string | null | undefined;
              emails_attribute?: string | null | undefined;
              username_attribute?: string | null | undefined;
              first_name_attribute?: string | null | undefined;
              last_name_attribute?: string | null | undefined;
              group_name_attribute?: string | null | undefined;
            }
          | null
          | undefined;
        customAttributeMappings: Array<{
          __typename?: 'Portal_DirectoryCustomAttributeMapping';
          id: string;
          attribute: string;
          customAttributeId: string;
        }>;
        countsSummary: {
          __typename?: 'Portal_DirectoryCountsSummary';
          userCount: number;
          groupCount: number;
        };
      }
    | null
    | undefined;
  organization: {
    __typename?: 'Organization';
    id: string;
    name: string;
    domains: Array<{
      __typename?: 'OrganizationDomain';
      id: string;
      domain: string;
    }>;
  };
  portalSettings: {
    __typename?: 'PortalSettings';
    logoUrl?: string | null | undefined;
    primaryColor?: string | null | undefined;
    faviconUrl?: string | null | undefined;
  };
};

export type ReadyMutationVariables = Exact<{
  started_at: Scalars['Float'];
  legacy?: InputMaybe<Scalars['Boolean']>;
}>;

export type ReadyMutation = {
  __typename?: 'Mutation';
  portal_ready: { __typename?: 'SuccessResponse'; success: boolean };
};

export type RequestIdentityProviderMutationVariables = Exact<{
  identityProvider: Scalars['String'];
}>;

export type RequestIdentityProviderMutation = {
  __typename?: 'Mutation';
  portalIdentityProviderRequest: boolean;
};

export type SamlX509CertificateFragment = {
  __typename?: 'portal_SamlX509Certificate';
  id: string;
  value: string;
  notBefore?: any | null | undefined;
  notAfter?: any | null | undefined;
};

export type SetDirectoryCustomAttributeMappingsMutationVariables = Exact<{
  input: Portal_SetDirectoryCustomAttributeMappingsInput;
}>;

export type SetDirectoryCustomAttributeMappingsMutation = {
  __typename?: 'Mutation';
  portal_setDirectoryCustomAttributeMappings:
    | { __typename: 'DirectoryCustomAttributeNotFound' }
    | { __typename: 'DirectoryNotFound' }
    | {
        __typename: 'Portal_DirectoryCustomAttributeMappingsSet';
        mappings: Array<{
          __typename?: 'Portal_DirectoryCustomAttributeMapping';
          id: string;
          attribute: string;
          customAttributeId: string;
        }>;
      };
};

export type TrackEventMutationVariables = Exact<{
  event_name: Scalars['String'];
  attributes?: InputMaybe<Scalars['JSON']>;
}>;

export type TrackEventMutation = {
  __typename?: 'Mutation';
  portal_trackEvent: { __typename?: 'SuccessResponse'; success: boolean };
};

export type UpdateAttributeMapMutationVariables = Exact<{
  id: Scalars['String'];
  idp_id_attribute?: InputMaybe<Scalars['String']>;
  email_attribute?: InputMaybe<Scalars['String']>;
  first_name_attribute?: InputMaybe<Scalars['String']>;
  last_name_attribute?: InputMaybe<Scalars['String']>;
}>;

export type UpdateAttributeMapMutation = {
  __typename?: 'Mutation';
  portal_updateAttributeMap?:
    | { __typename?: 'AttributeMap'; id: string }
    | null
    | undefined;
};

export type UpdateConnectionFromMetadataUrlMutationVariables = Exact<{
  input: Portal_UpdateConnectionFromMetadataUrlInput;
}>;

export type UpdateConnectionFromMetadataUrlMutation = {
  __typename?: 'Mutation';
  portal_updateConnectionFromMetadataUrl:
    | { __typename: 'ConnectionNotFound'; connectionId: string }
    | {
        __typename: 'InvalidSamlX509Certificate';
        validationError: SamlX509CertificateValidationErrorCode;
      }
    | {
        __typename: 'Portal_ConnectionUpdatedFromMetadataUrl';
        connection: {
          __typename?: 'portal_Connection';
          id: string;
          type: ConnectionType;
          createdAt: any;
          name: string;
          oidc_client_id?: string | null | undefined;
          oidc_client_secret?: string | null | undefined;
          oidc_discovery_endpoint?: string | null | undefined;
          oidc_redirect_uri?: string | null | undefined;
          status: ConnectionStatus;
          state: ConnectionState;
          saml_acs_url: string;
          saml_entity_id?: string | null | undefined;
          saml_idp_url?: string | null | undefined;
          saml_x509_certs?: Array<string> | null | undefined;
          saml_idp_metadata_url?: string | null | undefined;
          saml_sp_metadata_url: string;
          adpConnectionSslCertificates: Array<{
            __typename?: 'Portal_AdpConnectionSslCertificate';
            id: string;
            value: string;
          }>;
          attribute_map?:
            | { __typename?: 'AttributeMap'; id: string }
            | null
            | undefined;
          connectionDomains: Array<{
            __typename?: 'ConnectionDomain';
            id: string;
            domain: string;
          }>;
          samlX509Certificates: Array<{
            __typename?: 'portal_SamlX509Certificate';
            id: string;
            value: string;
            notBefore?: any | null | undefined;
            notAfter?: any | null | undefined;
          }>;
          saml: {
            __typename?: 'Portal_SamlConnection';
            idpEntityId?: string | null | undefined;
          };
        };
      }
    | { __typename: 'Portal_MetadataFetchFailed'; reason: string };
};

export type UpdateConnectionFromMetadataXmlMutationVariables = Exact<{
  input: Portal_UpdateConnectionFromMetadataXmlInput;
}>;

export type UpdateConnectionFromMetadataXmlMutation = {
  __typename?: 'Mutation';
  portal_updateConnectionFromMetadataXml:
    | { __typename: 'ConnectionNotFound'; connectionId: string }
    | {
        __typename: 'InvalidSamlX509Certificate';
        validationError: SamlX509CertificateValidationErrorCode;
      }
    | {
        __typename: 'Portal_ConnectionUpdatedFromMetadataXml';
        connection: {
          __typename?: 'portal_Connection';
          id: string;
          type: ConnectionType;
          createdAt: any;
          name: string;
          oidc_client_id?: string | null | undefined;
          oidc_client_secret?: string | null | undefined;
          oidc_discovery_endpoint?: string | null | undefined;
          oidc_redirect_uri?: string | null | undefined;
          status: ConnectionStatus;
          state: ConnectionState;
          saml_acs_url: string;
          saml_entity_id?: string | null | undefined;
          saml_idp_url?: string | null | undefined;
          saml_x509_certs?: Array<string> | null | undefined;
          saml_idp_metadata_url?: string | null | undefined;
          saml_sp_metadata_url: string;
          adpConnectionSslCertificates: Array<{
            __typename?: 'Portal_AdpConnectionSslCertificate';
            id: string;
            value: string;
          }>;
          attribute_map?:
            | { __typename?: 'AttributeMap'; id: string }
            | null
            | undefined;
          connectionDomains: Array<{
            __typename?: 'ConnectionDomain';
            id: string;
            domain: string;
          }>;
          samlX509Certificates: Array<{
            __typename?: 'portal_SamlX509Certificate';
            id: string;
            value: string;
            notBefore?: any | null | undefined;
            notAfter?: any | null | undefined;
          }>;
          saml: {
            __typename?: 'Portal_SamlConnection';
            idpEntityId?: string | null | undefined;
          };
        };
      }
    | { __typename: 'Portal_MetadataParseFailed'; reason: string };
};

export type UpdateConnectionMutationVariables = Exact<{
  input: Portal_UpdateConnectionInput;
}>;

export type UpdateConnectionMutation = {
  __typename?: 'Mutation';
  portal_updateConnection:
    | { __typename: 'ConnectionNotFound'; connectionId: string }
    | {
        __typename: 'InvalidSamlX509Certificate';
        validationError: SamlX509CertificateValidationErrorCode;
      }
    | {
        __typename: 'Portal_ConnectionUpdated';
        connection: {
          __typename?: 'portal_Connection';
          id: string;
          type: ConnectionType;
          createdAt: any;
          name: string;
          oidc_client_id?: string | null | undefined;
          oidc_client_secret?: string | null | undefined;
          oidc_discovery_endpoint?: string | null | undefined;
          oidc_redirect_uri?: string | null | undefined;
          status: ConnectionStatus;
          state: ConnectionState;
          saml_acs_url: string;
          saml_entity_id?: string | null | undefined;
          saml_idp_url?: string | null | undefined;
          saml_x509_certs?: Array<string> | null | undefined;
          saml_idp_metadata_url?: string | null | undefined;
          saml_sp_metadata_url: string;
          adpConnectionSslCertificates: Array<{
            __typename?: 'Portal_AdpConnectionSslCertificate';
            id: string;
            value: string;
          }>;
          attribute_map?:
            | { __typename?: 'AttributeMap'; id: string }
            | null
            | undefined;
          connectionDomains: Array<{
            __typename?: 'ConnectionDomain';
            id: string;
            domain: string;
          }>;
          samlX509Certificates: Array<{
            __typename?: 'portal_SamlX509Certificate';
            id: string;
            value: string;
            notBefore?: any | null | undefined;
            notAfter?: any | null | undefined;
          }>;
          saml: {
            __typename?: 'Portal_SamlConnection';
            idpEntityId?: string | null | undefined;
          };
        };
      }
    | {
        __typename: 'VerifyConnectionFailed';
        connectionErrors: Array<{
          __typename?: 'ConnectionError';
          error: string;
          field: string;
          solution: string;
        }>;
      };
};

export type UpdateDirectoryAttributeMapMutationVariables = Exact<{
  input: Portal_UpdateDirectoryAttributeMapInput;
}>;

export type UpdateDirectoryAttributeMapMutation = {
  __typename?: 'Mutation';
  portal_updateDirectoryAttributeMap?:
    | {
        __typename?: 'Portal_UpdateDirectoryAttributeMapPayload';
        directoryAttributeMap?:
          | {
              __typename?: 'DirectoryAttributeMap';
              id: string;
              external_id_attribute?: string | null | undefined;
              username_attribute?: string | null | undefined;
              emails_attribute?: string | null | undefined;
              first_name_attribute?: string | null | undefined;
              last_name_attribute?: string | null | undefined;
              group_name_attribute?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UpdateDirectoryMutationVariables = Exact<{
  id: Scalars['String'];
  bamboo_hr_api_key?: InputMaybe<Scalars['String']>;
  bamboo_hr_subdomain?: InputMaybe<Scalars['String']>;
  breatheHrApiKey?: InputMaybe<Scalars['String']>;
  bob_api_token?: InputMaybe<Scalars['String']>;
  bob_service_user_id?: InputMaybe<Scalars['String']>;
  rippling_api_key?: InputMaybe<Scalars['String']>;
  workday_raas_endpoint?: InputMaybe<Scalars['String']>;
  workday_raas_group_endpoint?: InputMaybe<Scalars['String']>;
  workday_raas_password?: InputMaybe<Scalars['String']>;
  workday_raas_username?: InputMaybe<Scalars['String']>;
  fourthHrOrganizationId?: InputMaybe<Scalars['String']>;
  fourthHrPassword?: InputMaybe<Scalars['String']>;
  fourthHrUsername?: InputMaybe<Scalars['String']>;
}>;

export type UpdateDirectoryMutation = {
  __typename?: 'Mutation';
  portal_updateDirectory: {
    __typename?: 'portal_Directory';
    id: string;
    name: string;
    type: DirectoryType;
    state: DirectoryState;
    endpoint?: string | null | undefined;
    setup_url?: string | null | undefined;
    createdAt: any;
    bearer_token?: string | null | undefined;
    bamboo_hr_api_key?: string | null | undefined;
    breatheHrApiKey?: string | null | undefined;
    bamboo_hr_subdomain?: string | null | undefined;
    bob_service_user_id?: string | null | undefined;
    bob_api_token?: string | null | undefined;
    rippling_api_key?: string | null | undefined;
    workday_raas_endpoint?: string | null | undefined;
    workday_raas_group_endpoint?: string | null | undefined;
    workday_raas_password?: string | null | undefined;
    workday_raas_username?: string | null | undefined;
    fourthHrOrganizationId?: string | null | undefined;
    fourthHrPassword?: string | null | undefined;
    fourthHrUsername?: string | null | undefined;
    attributeMap?:
      | {
          __typename?: 'DirectoryAttributeMap';
          id: string;
          external_id_attribute?: string | null | undefined;
          emails_attribute?: string | null | undefined;
          username_attribute?: string | null | undefined;
          first_name_attribute?: string | null | undefined;
          last_name_attribute?: string | null | undefined;
          group_name_attribute?: string | null | undefined;
        }
      | null
      | undefined;
    customAttributeMappings: Array<{
      __typename?: 'Portal_DirectoryCustomAttributeMapping';
      id: string;
      attribute: string;
      customAttributeId: string;
    }>;
    countsSummary: {
      __typename?: 'Portal_DirectoryCountsSummary';
      userCount: number;
      groupCount: number;
    };
  };
};

export const ConnectionSessionErrorFragmentDoc = gql`
  fragment ConnectionSessionError on ConnectionSessionError {
    __typename
    ... on MalformedSamlResponseError {
      code
      reason
    }
    ... on InvalidX509CertError {
      code
      reason
    }
    ... on InvalidAttributesError {
      code
      reason
      received_attributes {
        attribute
        value
      }
      expected_attributes {
        email_attribute
        first_name_attribute
        idp_id_attribute
        last_name_attribute
      }
    }
    ... on ProfileNotAllowedOutsideOrganizationError {
      code
      reason
      profileDomain
      organizationDomains
    }
    ... on DecryptionFailedError {
      code
      reason
    }
  }
`;
export const ConnectionSessionFragmentDoc = gql`
  fragment ConnectionSession on ConnectionSession {
    __typename
    ... on SAMLSession {
      createdAt
      id
      saml_request
      saml_response
      samlSessionState: state
      profile {
        idp_id
        email
        first_name
        last_name
      }
      errors {
        ...ConnectionSessionError
      }
    }
    ... on OAuthSession {
      createdAt
      id
      oauthSessionState: state
      profile {
        idp_id
        email
        first_name
        last_name
        rawAttributes
      }
      errors {
        ...ConnectionSessionError
      }
    }
    ... on OidcSession {
      createdAt
      id
      oidcSessionState: state
      profile {
        idp_id
        email
        first_name
        last_name
        rawAttributes
      }
      errors {
        ...ConnectionSessionError
      }
    }
  }
  ${ConnectionSessionErrorFragmentDoc}
`;
export const ConnectionSessionContainerFragmentDoc = gql`
  fragment ConnectionSessionContainer on portal_Connection {
    samlSessions(state: $samlSessionState) {
      data {
        ...ConnectionSession
      }
    }
    oauthSessions(state: $oauthSessionState) {
      data {
        ...ConnectionSession
      }
    }
    oidcSessions(state: $oidcSessionState) {
      data {
        ...ConnectionSession
      }
    }
  }
  ${ConnectionSessionFragmentDoc}
`;
export const DirectoryCustomAttributeFragmentDoc = gql`
  fragment DirectoryCustomAttribute on Portal_DirectoryCustomAttribute {
    id
    isRequired
    name
  }
`;
export const DirectorySyncRunFragmentDoc = gql`
  fragment DirectorySyncRun on Portal_DirectorySyncRun {
    id
    state
    updatedAt
    errors {
      __typename
      ... on MalformedDirectoryUserError {
        code
        reason
      }
      ... on MalformedDirectoryGroupError {
        code
        reason
      }
    }
  }
`;
export const DirectoryGroupFragmentDoc = gql`
  fragment DirectoryGroup on DirectoryGroup {
    id
    name
  }
`;
export const DirectoryUserFragmentDoc = gql`
  fragment DirectoryUser on DirectoryUser {
    id
    firstName
    lastName
    username
    state
    directoryGroups {
      ...DirectoryGroup
    }
  }
  ${DirectoryGroupFragmentDoc}
`;
export const ConnectionDomainFragmentDoc = gql`
  fragment ConnectionDomain on ConnectionDomain {
    id
    domain
  }
`;
export const SamlX509CertificateFragmentDoc = gql`
  fragment SamlX509Certificate on portal_SamlX509Certificate {
    id
    value
    notBefore
    notAfter
  }
`;
export const ConnectionFragmentDoc = gql`
  fragment Connection on portal_Connection {
    id
    adpConnectionSslCertificates {
      id
      value
    }
    attribute_map {
      id
    }
    type
    connectionDomains {
      ...ConnectionDomain
    }
    createdAt
    name
    oidc_client_id
    oidc_client_secret
    oidc_discovery_endpoint
    oidc_redirect_uri
    status
    state
    saml_acs_url
    saml_entity_id
    saml_idp_url
    saml_x509_certs
    samlX509Certificates {
      ...SamlX509Certificate
    }
    saml_idp_metadata_url
    saml_sp_metadata_url
    saml {
      idpEntityId
    }
  }
  ${ConnectionDomainFragmentDoc}
  ${SamlX509CertificateFragmentDoc}
`;
export const DirectoryCustomAttributeMappingFragmentDoc = gql`
  fragment DirectoryCustomAttributeMapping on Portal_DirectoryCustomAttributeMapping {
    id
    attribute
    customAttributeId
  }
`;
export const DirectoryFragmentDoc = gql`
  fragment Directory on portal_Directory {
    id
    attributeMap {
      id
      external_id_attribute
      emails_attribute
      username_attribute
      emails_attribute
      first_name_attribute
      last_name_attribute
      group_name_attribute
    }
    customAttributeMappings {
      ...DirectoryCustomAttributeMapping
    }
    countsSummary {
      userCount
      groupCount
    }
    name
    type
    state
    endpoint
    setup_url
    createdAt
    bearer_token
    bamboo_hr_api_key
    breatheHrApiKey
    bamboo_hr_subdomain
    bob_service_user_id
    bob_api_token
    rippling_api_key
    workday_raas_endpoint
    workday_raas_group_endpoint
    workday_raas_password
    workday_raas_username
    fourthHrOrganizationId
    fourthHrPassword
    fourthHrUsername
  }
  ${DirectoryCustomAttributeMappingFragmentDoc}
`;
export const OrganizationFragmentDoc = gql`
  fragment Organization on Organization {
    id
    name
    domains {
      id
      domain
    }
  }
`;
export const PortalSessionFragmentDoc = gql`
  fragment PortalSession on portal_PortalSession {
    id
    returnUrl
    appName
    connection {
      ...Connection
    }
    directory {
      ...Directory
    }
    organization {
      ...Organization
    }
    portalSettings {
      logoUrl
      primaryColor
      faviconUrl
    }
  }
  ${ConnectionFragmentDoc}
  ${DirectoryFragmentDoc}
  ${OrganizationFragmentDoc}
`;
export const AddAdpConnectionSslCertificateDocument = gql`
  mutation AddAdpConnectionSslCertificate(
    $input: Portal_AddAdpConnectionSslCertificateInput!
  ) {
    portal_addAdpConnectionSslCertificate(input: $input) {
      __typename
      ... on Portal_AdpConnectionSslCertificateAdded {
        adpConnectionSslCertificate {
          id
          value
        }
      }
      ... on ConnectionNotFound {
        connectionId
      }
    }
  }
`;
export const ConnectionSessionDocument = gql`
  query ConnectionSession($id: String!) {
    portal_connectionSession(id: $id) {
      ...ConnectionSession
    }
  }
  ${ConnectionSessionFragmentDoc}
`;
export const ConnectionSessionsDocument = gql`
  query ConnectionSessions(
    $samlSessionState: SAMLSessionState
    $oauthSessionState: OAuthSessionState
    $oidcSessionState: OidcSessionState
  ) {
    portal_connections {
      data {
        ...ConnectionSessionContainer
      }
    }
  }
  ${ConnectionSessionContainerFragmentDoc}
`;
export const ConnectionsDocument = gql`
  query Connections {
    portal_connections {
      data {
        ...Connection
      }
    }
  }
  ${ConnectionFragmentDoc}
`;
export const CreateConnectionDocument = gql`
  mutation CreateConnection(
    $name: String!
    $type: ConnectionType!
    $domains: [String!]
  ) {
    portal_createConnection(
      name: $name
      connection_type: $type
      domains: $domains
    ) {
      ...Connection
    }
  }
  ${ConnectionFragmentDoc}
`;
export const CreateDirectoryDocument = gql`
  mutation CreateDirectory(
    $name: String!
    $type: DirectoryType!
    $domain: String
  ) {
    portal_createDirectory(name: $name, type: $type, domain: $domain) {
      ...Directory
    }
  }
  ${DirectoryFragmentDoc}
`;
export const DeleteDirectoryDocument = gql`
  mutation DeleteDirectory($id: String!) {
    portal_deleteDirectory(input: { id: $id }) {
      directory {
        id
      }
    }
  }
`;
export const DeleteDraftConnectionDocument = gql`
  mutation DeleteDraftConnection($id: String!) {
    portal_deleteDraftConnection(id: $id)
  }
`;
export const DeleteSamlX509CertificateDocument = gql`
  mutation DeleteSamlX509Certificate(
    $input: Portal_DeleteSamlX509CertificateInput!
  ) {
    portal_deleteSamlX509Certificate(input: $input) {
      __typename
      ... on Portal_SamlX509CertificateDeleted {
        samlX509Certificate {
          id
        }
      }
      ... on SamlX509CertificateNotFound {
        samlX509CertificateId
      }
    }
  }
`;
export const DirectoryCustomAttributesDocument = gql`
  query DirectoryCustomAttributes($directoryId: String!) {
    portal_directoryCustomAttributes(directoryId: $directoryId) {
      data {
        ...DirectoryCustomAttribute
      }
    }
  }
  ${DirectoryCustomAttributeFragmentDoc}
`;
export const DirectoryOverviewDocument = gql`
  query DirectoryOverview(
    $id: String!
    $limit: Int = 1
    $state: DirectorySyncRunState
  ) {
    portal_directory(id: $id) {
      ...Directory
      directorySyncRuns(limit: $limit, state: $state) {
        data {
          ...DirectorySyncRun
        }
      }
    }
  }
  ${DirectoryFragmentDoc}
  ${DirectorySyncRunFragmentDoc}
`;
export const DirectoryUsersDocument = gql`
  query DirectoryUsers(
    $directoryId: String!
    $before: String
    $after: String
    $limit: Int
  ) {
    portal_directory(id: $directoryId) {
      directoryUsers(before: $before, after: $after, limit: $limit) {
        data {
          ...DirectoryUser
        }
        listMetadata {
          before
          after
        }
      }
    }
  }
  ${DirectoryUserFragmentDoc}
`;
export const DirectoryDocument = gql`
  query Directory($id: String!) {
    portal_directory(id: $id) {
      ...Directory
    }
  }
  ${DirectoryFragmentDoc}
`;
export const InitialDsyncQueryDocument = gql`
  query InitialDsyncQuery {
    portal_portalSession {
      ...PortalSession
    }
    portal_directories {
      data {
        ...Directory
      }
    }
  }
  ${PortalSessionFragmentDoc}
  ${DirectoryFragmentDoc}
`;
export const InitialPortalDocument = gql`
  query InitialPortal {
    portal_portalSession {
      ...PortalSession
    }
  }
  ${PortalSessionFragmentDoc}
`;
export const InitialSsoQueryDocument = gql`
  query InitialSSOQuery {
    portal_portalSession {
      ...PortalSession
    }
    portal_connections {
      data {
        ...Connection
      }
    }
  }
  ${PortalSessionFragmentDoc}
  ${ConnectionFragmentDoc}
`;
export const LoadedDocument = gql`
  mutation Loaded($started_at: Float!, $legacy: Boolean) {
    portal_loaded(started_at: $started_at, legacy: $legacy) {
      success
    }
  }
`;
export const ReadyDocument = gql`
  mutation Ready($started_at: Float!, $legacy: Boolean) {
    portal_ready(started_at: $started_at, legacy: $legacy) {
      success
    }
  }
`;
export const RequestIdentityProviderDocument = gql`
  mutation RequestIdentityProvider($identityProvider: String!) {
    portalIdentityProviderRequest(identityProvider: $identityProvider)
  }
`;
export const SetDirectoryCustomAttributeMappingsDocument = gql`
  mutation SetDirectoryCustomAttributeMappings(
    $input: Portal_SetDirectoryCustomAttributeMappingsInput!
  ) {
    portal_setDirectoryCustomAttributeMappings(input: $input) {
      __typename
      ... on Portal_DirectoryCustomAttributeMappingsSet {
        mappings {
          ...DirectoryCustomAttributeMapping
        }
      }
    }
  }
  ${DirectoryCustomAttributeMappingFragmentDoc}
`;
export const TrackEventDocument = gql`
  mutation TrackEvent($event_name: String!, $attributes: JSON) {
    portal_trackEvent(event_name: $event_name, attributes: $attributes) {
      success
    }
  }
`;
export const UpdateAttributeMapDocument = gql`
  mutation UpdateAttributeMap(
    $id: String!
    $idp_id_attribute: String
    $email_attribute: String
    $first_name_attribute: String
    $last_name_attribute: String
  ) {
    portal_updateAttributeMap(
      attribute_map_id: $id
      idp_id_attribute: $idp_id_attribute
      email_attribute: $email_attribute
      first_name_attribute: $first_name_attribute
      last_name_attribute: $last_name_attribute
    ) {
      id
    }
  }
`;
export const UpdateConnectionFromMetadataUrlDocument = gql`
  mutation UpdateConnectionFromMetadataUrl(
    $input: Portal_UpdateConnectionFromMetadataUrlInput!
  ) {
    portal_updateConnectionFromMetadataUrl(input: $input) {
      __typename
      ... on Portal_ConnectionUpdatedFromMetadataUrl {
        connection {
          ...Connection
        }
      }
      ... on ConnectionNotFound {
        connectionId
      }
      ... on Portal_MetadataFetchFailed {
        reason
      }
      ... on InvalidSamlX509Certificate {
        validationError
      }
    }
  }
  ${ConnectionFragmentDoc}
`;
export const UpdateConnectionFromMetadataXmlDocument = gql`
  mutation UpdateConnectionFromMetadataXml(
    $input: Portal_UpdateConnectionFromMetadataXmlInput!
  ) {
    portal_updateConnectionFromMetadataXml(input: $input) {
      __typename
      ... on Portal_ConnectionUpdatedFromMetadataXml {
        connection {
          ...Connection
        }
      }
      ... on ConnectionNotFound {
        connectionId
      }
      ... on Portal_MetadataParseFailed {
        reason
      }
      ... on InvalidSamlX509Certificate {
        validationError
      }
    }
  }
  ${ConnectionFragmentDoc}
`;
export const UpdateConnectionDocument = gql`
  mutation UpdateConnection($input: Portal_UpdateConnectionInput!) {
    portal_updateConnection(input: $input) {
      __typename
      ... on Portal_ConnectionUpdated {
        connection {
          ...Connection
        }
      }
      ... on ConnectionNotFound {
        connectionId
      }
      ... on InvalidSamlX509Certificate {
        validationError
      }
      ... on VerifyConnectionFailed {
        connectionErrors {
          error
          field
          solution
        }
      }
    }
  }
  ${ConnectionFragmentDoc}
`;
export const UpdateDirectoryAttributeMapDocument = gql`
  mutation UpdateDirectoryAttributeMap(
    $input: Portal_UpdateDirectoryAttributeMapInput!
  ) {
    portal_updateDirectoryAttributeMap(input: $input) {
      directoryAttributeMap {
        id
        external_id_attribute
        username_attribute
        emails_attribute
        first_name_attribute
        last_name_attribute
        group_name_attribute
      }
    }
  }
`;
export const UpdateDirectoryDocument = gql`
  mutation UpdateDirectory(
    $id: String!
    $bamboo_hr_api_key: String
    $bamboo_hr_subdomain: String
    $breatheHrApiKey: String
    $bob_api_token: String
    $bob_service_user_id: String
    $rippling_api_key: String
    $workday_raas_endpoint: String
    $workday_raas_group_endpoint: String
    $workday_raas_password: String
    $workday_raas_username: String
    $fourthHrOrganizationId: String
    $fourthHrPassword: String
    $fourthHrUsername: String
  ) {
    portal_updateDirectory(
      directory_id: $id
      bamboo_hr_api_key: $bamboo_hr_api_key
      bamboo_hr_subdomain: $bamboo_hr_subdomain
      breatheHrApiKey: $breatheHrApiKey
      bob_api_token: $bob_api_token
      bob_service_user_id: $bob_service_user_id
      rippling_api_key: $rippling_api_key
      workday_raas_endpoint: $workday_raas_endpoint
      workday_raas_group_endpoint: $workday_raas_group_endpoint
      workday_raas_password: $workday_raas_password
      workday_raas_username: $workday_raas_username
      fourthHrOrganizationId: $fourthHrOrganizationId
      fourthHrPassword: $fourthHrPassword
      fourthHrUsername: $fourthHrUsername
    ) {
      ...Directory
    }
  }
  ${DirectoryFragmentDoc}
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName) => action();
const AddAdpConnectionSslCertificateDocumentString = print(
  AddAdpConnectionSslCertificateDocument,
);
const ConnectionSessionDocumentString = print(ConnectionSessionDocument);
const ConnectionSessionsDocumentString = print(ConnectionSessionsDocument);
const ConnectionsDocumentString = print(ConnectionsDocument);
const CreateConnectionDocumentString = print(CreateConnectionDocument);
const CreateDirectoryDocumentString = print(CreateDirectoryDocument);
const DeleteDirectoryDocumentString = print(DeleteDirectoryDocument);
const DeleteDraftConnectionDocumentString = print(
  DeleteDraftConnectionDocument,
);
const DeleteSamlX509CertificateDocumentString = print(
  DeleteSamlX509CertificateDocument,
);
const DirectoryCustomAttributesDocumentString = print(
  DirectoryCustomAttributesDocument,
);
const DirectoryOverviewDocumentString = print(DirectoryOverviewDocument);
const DirectoryUsersDocumentString = print(DirectoryUsersDocument);
const DirectoryDocumentString = print(DirectoryDocument);
const InitialDsyncQueryDocumentString = print(InitialDsyncQueryDocument);
const InitialPortalDocumentString = print(InitialPortalDocument);
const InitialSsoQueryDocumentString = print(InitialSsoQueryDocument);
const LoadedDocumentString = print(LoadedDocument);
const ReadyDocumentString = print(ReadyDocument);
const RequestIdentityProviderDocumentString = print(
  RequestIdentityProviderDocument,
);
const SetDirectoryCustomAttributeMappingsDocumentString = print(
  SetDirectoryCustomAttributeMappingsDocument,
);
const TrackEventDocumentString = print(TrackEventDocument);
const UpdateAttributeMapDocumentString = print(UpdateAttributeMapDocument);
const UpdateConnectionFromMetadataUrlDocumentString = print(
  UpdateConnectionFromMetadataUrlDocument,
);
const UpdateConnectionFromMetadataXmlDocumentString = print(
  UpdateConnectionFromMetadataXmlDocument,
);
const UpdateConnectionDocumentString = print(UpdateConnectionDocument);
const UpdateDirectoryAttributeMapDocumentString = print(
  UpdateDirectoryAttributeMapDocument,
);
const UpdateDirectoryDocumentString = print(UpdateDirectoryDocument);
export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper,
) {
  return {
    AddAdpConnectionSslCertificate(
      variables: AddAdpConnectionSslCertificateMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data?: AddAdpConnectionSslCertificateMutation | undefined;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
      errors?: GraphQLError[] | undefined;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<AddAdpConnectionSslCertificateMutation>(
            AddAdpConnectionSslCertificateDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'AddAdpConnectionSslCertificate',
      );
    },
    ConnectionSession(
      variables: ConnectionSessionQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data?: ConnectionSessionQuery | undefined;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
      errors?: GraphQLError[] | undefined;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<ConnectionSessionQuery>(
            ConnectionSessionDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'ConnectionSession',
      );
    },
    ConnectionSessions(
      variables?: ConnectionSessionsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data?: ConnectionSessionsQuery | undefined;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
      errors?: GraphQLError[] | undefined;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<ConnectionSessionsQuery>(
            ConnectionSessionsDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'ConnectionSessions',
      );
    },
    Connections(
      variables?: ConnectionsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data?: ConnectionsQuery | undefined;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
      errors?: GraphQLError[] | undefined;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<ConnectionsQuery>(
            ConnectionsDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'Connections',
      );
    },
    CreateConnection(
      variables: CreateConnectionMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data?: CreateConnectionMutation | undefined;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
      errors?: GraphQLError[] | undefined;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<CreateConnectionMutation>(
            CreateConnectionDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'CreateConnection',
      );
    },
    CreateDirectory(
      variables: CreateDirectoryMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data?: CreateDirectoryMutation | undefined;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
      errors?: GraphQLError[] | undefined;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<CreateDirectoryMutation>(
            CreateDirectoryDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'CreateDirectory',
      );
    },
    DeleteDirectory(
      variables: DeleteDirectoryMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data?: DeleteDirectoryMutation | undefined;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
      errors?: GraphQLError[] | undefined;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<DeleteDirectoryMutation>(
            DeleteDirectoryDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'DeleteDirectory',
      );
    },
    DeleteDraftConnection(
      variables: DeleteDraftConnectionMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data?: DeleteDraftConnectionMutation | undefined;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
      errors?: GraphQLError[] | undefined;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<DeleteDraftConnectionMutation>(
            DeleteDraftConnectionDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'DeleteDraftConnection',
      );
    },
    DeleteSamlX509Certificate(
      variables: DeleteSamlX509CertificateMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data?: DeleteSamlX509CertificateMutation | undefined;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
      errors?: GraphQLError[] | undefined;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<DeleteSamlX509CertificateMutation>(
            DeleteSamlX509CertificateDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'DeleteSamlX509Certificate',
      );
    },
    DirectoryCustomAttributes(
      variables: DirectoryCustomAttributesQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data?: DirectoryCustomAttributesQuery | undefined;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
      errors?: GraphQLError[] | undefined;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<DirectoryCustomAttributesQuery>(
            DirectoryCustomAttributesDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'DirectoryCustomAttributes',
      );
    },
    DirectoryOverview(
      variables: DirectoryOverviewQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data?: DirectoryOverviewQuery | undefined;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
      errors?: GraphQLError[] | undefined;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<DirectoryOverviewQuery>(
            DirectoryOverviewDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'DirectoryOverview',
      );
    },
    DirectoryUsers(
      variables: DirectoryUsersQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data?: DirectoryUsersQuery | undefined;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
      errors?: GraphQLError[] | undefined;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<DirectoryUsersQuery>(
            DirectoryUsersDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'DirectoryUsers',
      );
    },
    Directory(
      variables: DirectoryQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data?: DirectoryQuery | undefined;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
      errors?: GraphQLError[] | undefined;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<DirectoryQuery>(
            DirectoryDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'Directory',
      );
    },
    InitialDsyncQuery(
      variables?: InitialDsyncQueryQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data?: InitialDsyncQueryQuery | undefined;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
      errors?: GraphQLError[] | undefined;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<InitialDsyncQueryQuery>(
            InitialDsyncQueryDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'InitialDsyncQuery',
      );
    },
    InitialPortal(
      variables?: InitialPortalQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data?: InitialPortalQuery | undefined;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
      errors?: GraphQLError[] | undefined;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<InitialPortalQuery>(
            InitialPortalDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'InitialPortal',
      );
    },
    InitialSSOQuery(
      variables?: InitialSsoQueryQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data?: InitialSsoQueryQuery | undefined;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
      errors?: GraphQLError[] | undefined;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<InitialSsoQueryQuery>(
            InitialSsoQueryDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'InitialSSOQuery',
      );
    },
    Loaded(
      variables: LoadedMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data?: LoadedMutation | undefined;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
      errors?: GraphQLError[] | undefined;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<LoadedMutation>(LoadedDocumentString, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'Loaded',
      );
    },
    Ready(
      variables: ReadyMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data?: ReadyMutation | undefined;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
      errors?: GraphQLError[] | undefined;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<ReadyMutation>(ReadyDocumentString, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'Ready',
      );
    },
    RequestIdentityProvider(
      variables: RequestIdentityProviderMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data?: RequestIdentityProviderMutation | undefined;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
      errors?: GraphQLError[] | undefined;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<RequestIdentityProviderMutation>(
            RequestIdentityProviderDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'RequestIdentityProvider',
      );
    },
    SetDirectoryCustomAttributeMappings(
      variables: SetDirectoryCustomAttributeMappingsMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data?: SetDirectoryCustomAttributeMappingsMutation | undefined;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
      errors?: GraphQLError[] | undefined;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<SetDirectoryCustomAttributeMappingsMutation>(
            SetDirectoryCustomAttributeMappingsDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'SetDirectoryCustomAttributeMappings',
      );
    },
    TrackEvent(
      variables: TrackEventMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data?: TrackEventMutation | undefined;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
      errors?: GraphQLError[] | undefined;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<TrackEventMutation>(
            TrackEventDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'TrackEvent',
      );
    },
    UpdateAttributeMap(
      variables: UpdateAttributeMapMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data?: UpdateAttributeMapMutation | undefined;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
      errors?: GraphQLError[] | undefined;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<UpdateAttributeMapMutation>(
            UpdateAttributeMapDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'UpdateAttributeMap',
      );
    },
    UpdateConnectionFromMetadataUrl(
      variables: UpdateConnectionFromMetadataUrlMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data?: UpdateConnectionFromMetadataUrlMutation | undefined;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
      errors?: GraphQLError[] | undefined;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<UpdateConnectionFromMetadataUrlMutation>(
            UpdateConnectionFromMetadataUrlDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'UpdateConnectionFromMetadataUrl',
      );
    },
    UpdateConnectionFromMetadataXml(
      variables: UpdateConnectionFromMetadataXmlMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data?: UpdateConnectionFromMetadataXmlMutation | undefined;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
      errors?: GraphQLError[] | undefined;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<UpdateConnectionFromMetadataXmlMutation>(
            UpdateConnectionFromMetadataXmlDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'UpdateConnectionFromMetadataXml',
      );
    },
    UpdateConnection(
      variables: UpdateConnectionMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data?: UpdateConnectionMutation | undefined;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
      errors?: GraphQLError[] | undefined;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<UpdateConnectionMutation>(
            UpdateConnectionDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'UpdateConnection',
      );
    },
    UpdateDirectoryAttributeMap(
      variables: UpdateDirectoryAttributeMapMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data?: UpdateDirectoryAttributeMapMutation | undefined;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
      errors?: GraphQLError[] | undefined;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<UpdateDirectoryAttributeMapMutation>(
            UpdateDirectoryAttributeMapDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'UpdateDirectoryAttributeMap',
      );
    },
    UpdateDirectory(
      variables: UpdateDirectoryMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<{
      data?: UpdateDirectoryMutation | undefined;
      extensions?: any;
      headers: Dom.Headers;
      status: number;
      errors?: GraphQLError[] | undefined;
    }> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.rawRequest<UpdateDirectoryMutation>(
            UpdateDirectoryDocumentString,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'UpdateDirectory',
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
